import useMountEffect from '@restart/hooks/useMountEffect';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import actions from '@/actions';
import EventEmitter from '@/libs/EventEmitter';

const useWaitingRobot = (robotId) => {
  const dispatch = useDispatch();
  const [isWaiting, setIsWaiting] = useState(true);

  useMountEffect(() => {
    EventEmitter.subscribe(`${robotId}/event/waitingRobot`, () => {
      setIsWaiting(true);
    });

    EventEmitter.subscribe(`${robotId}/event/reconnectRobot`, () => {
      setIsWaiting(false);
    });

    EventEmitter.subscribe(`${robotId}/event/disconnectRobot`, () => {
      dispatch(actions.event.remove(robotId));
      dispatch(actions.mission.unload(robotId));
      dispatch(actions.notification.remove(robotId));
      dispatch(actions.robot.deactivate(robotId));
      dispatch(actions.telemetry.remove(robotId));
    });
  });

  return [isWaiting];
};

export default useWaitingRobot;
