import classNames from 'classnames/bind';
import React from 'react';

import styles from './EmptyList.module.scss';

const cx = classNames.bind(styles);

const EmptyList = ({ message = 'No items found' }) => {
  return <div className={cx('container')}>{message}</div>;
};

export default EmptyList;
