import useMountEffect from '@restart/hooks/useMountEffect';
import React, { useState } from 'react';

import { Mode } from '@/components/ui/Dashboard';
import EventEmitter from '@/libs/EventEmitter';

const MODE = {
  0: 'Manual',
  1: 'Circle',
  2: 'Stabilize',
  3: 'Traning',
  4: 'Acro',
  5: 'FBWA',
  6: 'FBWB',
  7: 'Cruise',
  8: 'AutoTune',
  10: 'Auto',
  11: 'RTL',
  12: 'Loiter',
  13: 'Takeoff',
  14: 'Avoid ADSB',
  15: 'Guided',
  17: 'QStabilize',
  18: 'QHover',
  19: 'QLoiter',
  20: 'QLand',
  21: 'QRTL',
  22: 'QAutoTune',
  23: 'QAcro',
  24: 'Thermal',
  25: 'Loiter to QLand',
};

const Component = ({ robotId }) => {
  const [mode, setMode] = useState();

  useMountEffect(() => {
    const subscribeToken = EventEmitter.subscribe(`${robotId}/telemetry/heartbeat`, (data) => {
      setMode(MODE[data.mode]);
    });

    return () => {
      EventEmitter.unsubscribe(subscribeToken);
    };
  });

  return <Mode robotId={robotId} mode={mode} />;
};

export default Component;
