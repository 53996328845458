import { MavLinkData, MavLinkPacketField } from 'node-mavlink';

class UsvEmergencyGcsData extends MavLinkData {
  constructor() {
    super();
    this.targetSysid = 0;
    this.emergencyStop = 0;
  }
}

UsvEmergencyGcsData.MSG_ID = 1102;
UsvEmergencyGcsData.MSG_NAME = 'UsvEmergencyGcsData';
UsvEmergencyGcsData.PAYLOAD_LENGTH = 2;
UsvEmergencyGcsData.MAGIC_NUMBER = 95;
UsvEmergencyGcsData.FIELDS = [
  new MavLinkPacketField('targetSysid', 'targetSysid', 0, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('emergencyStop', 'emergencyStop', 1, false, 1, 'uint8_t', ''),
];

export default UsvEmergencyGcsData;
