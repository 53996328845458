//! Be sure to review and update the 'ArduPilot.js' file
//! whenever you make changes to this file.

const toMavLink = (missionItems) => {
  const messages = [];
  missionItems.forEach((missionItem) => {
    // Change Speed
    if (missionItem.type === 'doChangeSpeed') {
      messages.push([
        messages.length,
        2, // MAV_FRAME_MISSION
        178, // MAV_CMD_DO_CHANGE_SPEED
        0, // current
        1, // speed type: SPEED_TYPE_GROUNDSPEED
        missionItem.data.speed, // speed
        -1, // throttle: no change
        0, // param4
        0, // x
        0, // y
        0, // z
      ]);
    }
    // Gripper
    else if (missionItem.type === 'doGripper') {
      messages.push([
        messages.length,
        2, // MAV_FRAME_MISSION
        211, // MAV_CMD_DO_GRIPPER
        0, // current
        missionItem.data.gripper,
        missionItem.data.action,
        0, // param3
        0, // param4
        0, // x
        0, // y
        0, // z
      ]);
    }
    // Set Camera Trigger Distance
    else if (missionItem.type === 'doSetCamTriggDist') {
      messages.push([
        messages.length,
        2, // MAV_FRAME_MISSION
        206, // MAV_CMD_DO_SET_CAM_TRIGG_DIST
        0, // current
        missionItem.data.distance, // distance
        0, // camera shutter integration time: ignore
        1, // trigger camera once immediately
        0, // param4
        0, // x
        0, // y
        0, // z
      ]);
    }
    // Set Servo
    else if (missionItem.type === 'doSetServo') {
      messages.push([
        messages.length,
        2, // MAV_FRAME_MISSION
        183, // MAV_CMD_DO_SET_SERVO
        0, // current
        missionItem.data.servo,
        missionItem.data.pwm,
        0, // param3
        0, // param4
        0, // x
        0, // y
        0, // z
      ]);
    }
    // Land
    else if (missionItem.type === 'navLand') {
      // 낙하산 사용 시
      if (missionItem.data.parachute) {
        messages.push([
          messages.length,
          3, // MAV_FRAME_GLOBAL_RELATIVE_ALT
          16, // MAV_CMD_NAV_WAYPOINT
          0, // current
          0, // param1
          0, // param2
          0, // param3
          NaN, // param4
          missionItem.data.position.lat * 10000000,
          missionItem.data.position.lng * 10000000,
          missionItem.data.altitude,
        ]);
        messages.push([
          messages.length,
          2, // MAV_FRAME_MISSION
          208, // MAV_CMD_DO_PARACHUTE
          0, // current
          1, // parachute action: PARACHUTE_ENABLE(1)
          0, // param2
          0, // param3
          0, // param4
          0, // param5
          0, // param6
          0, // param7
        ]);
        messages.push([
          messages.length,
          2, // MAV_FRAME_MISSION
          208, // MAV_CMD_DO_PARACHUTE
          0, // current
          2, // parachute action: PARACHUTE_RELEASE(2)
          0, // param2
          0, // param3
          0, // param4
          0, // param5
          0, // param6
          0, // param7
        ]);
      }

      messages.push([
        messages.length,
        3, // MAV_FRAME_GLOBAL_RELATIVE_ALT
        21, // MAV_CMD_NAV_LAND
        0, // current
        0, // param1
        0, // param2
        0, // param3
        NaN, // param4
        missionItem.data.position.lat * 10000000,
        missionItem.data.position.lng * 10000000,
        0,
      ]);
    }
    // Loiter To Altitude
    else if (missionItem.type === 'navLoiterToAlt') {
      messages.push([
        messages.length,
        3, // MAV_FRAME_GLOBAL_RELATIVE_ALT
        31, // MAV_CMD_NAV_LOITER_TO_ALT
        0, // current
        1, // param1
        50, // param2
        0, // param3
        1, // param4
        missionItem.data.position.lat * 10000000,
        missionItem.data.position.lng * 10000000,
        missionItem.data.altitude,
      ]);
    }
    // Return To Launch
    else if (missionItem.type === 'navReturnToLaunch') {
      messages.push([
        messages.length,
        2, // MAV_FRAME_MISSION
        20, // MAV_CMD_NAV_RETURN_TO_LAUNCH
        0, // current
        0, // param1
        0, // param2
        0, // param3
        0, // param4
        0,
        0,
        0,
      ]);
    }
    // Takeoff
    else if (missionItem.type === 'navTakeoff') {
      messages.push([
        messages.length,
        3, // MAV_FRAME_GLOBAL_RELATIVE_ALT
        22, // MAV_CMD_NAV_TAKEOFF
        1, // current
        0, // param1
        0, // param2
        0, // param3
        NaN, // param4
        missionItem.data.position.lat * 10000000,
        missionItem.data.position.lng * 10000000,
        missionItem.data.altitude,
      ]);
    }
    // Waypoint
    else if (missionItem.type === 'navWaypoint') {
      messages.push([
        messages.length,
        3, // MAV_FRAME_GLOBAL_RELATIVE_ALT
        16, // MAV_CMD_NAV_WAYPOINT
        0, // current
        0, // param1
        0, // param2
        0, // param3
        NaN, // param4
        missionItem.data.position.lat * 10000000,
        missionItem.data.position.lng * 10000000,
        missionItem.data.altitude,
      ]);

      // 속도 변경하는 경우
      if (missionItem.data.speed) {
        messages.push([
          messages.length,
          2, // MAV_FRAME_MISSION
          178, // MAV_CMD_DO_CHANGE_SPEED
          0, // current
          1, // speed type: SPEED_TYPE_GROUNDSPEED
          missionItem.data.speed, // speed
          -1, // throttle: no change
          0, // param4
          0, // x
          0, // y
          0, // z
        ]);
      }
    }
  });
  return messages;
};

const fromMavLink = (messages) => {
  const missionItems = [];
  messages.forEach((message, index) => {
    // 16: MAV_CMD_NAV_WAYPOINT
    if (message.command === 16) {
      missionItems.push({
        id: index,
        type: 'navWaypoint',
        data: {
          name: '',
          position: {
            lat: message.x / 10000000,
            lng: message.y / 10000000,
          },
          altitude: message.z,
        },
      });
    }
    // 20: MAV_CMD_NAV_RETURN_TO_LAUNCH
    else if (message.command === 20) {
      missionItems.push({
        id: index,
        type: 'navReturnToLaunch',
      });
    }
    // 21: MAV_CMD_NAV_LAND
    else if (message.command === 21) {
      missionItems.push({
        id: index,
        type: 'navLand',
        data: {
          position: {
            lat: message.x / 10000000,
            lng: message.y / 10000000,
          },
        },
      });
    }
    // 22: MAV_CMD_NAV_TAKEOFF
    else if (message.command === 22) {
      missionItems.push({
        id: index,
        type: 'navTakeoff',
        data: {
          name: '',
          position: {
            lat: message.x / 10000000,
            lng: message.y / 10000000,
          },
          altitude: message.z,
        },
      });
    }
    // 31: MAV_CMD_NAV_LOITER_TO_ALT
    else if (message.command === 31) {
      missionItems.push({
        id: index,
        type: 'navLoiterToAlt',
        data: {
          position: {
            lat: message.x / 10000000,
            lng: message.y / 10000000,
          },
          altitude: message.z,
        },
      });
    }
    // 178: MAV_CMD_DO_CHANGE_SPEED
    else if (message.command === 178) {
      missionItems.push({
        id: index,
        type: 'doChangeSpeed',
        data: { speed: message.param2 },
      });
    }
    // 183: MAV_CMD_DO_SET_SERVO
    else if (message.command === 183) {
      missionItems.push({
        id: index,
        type: 'doSetServo',
        data: {
          servo: message.param1,
          pwm: message.param2,
        },
      });
    }
    // 206: MAV_CMD_DO_SET_CAM_TRIGG_DIST
    else if (message.command === 206) {
      missionItems.push({
        id: index,
        type: 'doSetCamTriggDist',
        data: { distance: message.param1 },
      });
    }
    // 208: MAV_CMD_DO_PARACHUTE
    else if (message.command === 208) {
      missionItems.push({
        id: index,
        type: 'doParachute',
        data: { action: message.param1 },
      });
    }
    // 211: MAV_CMD_DO_GRIPPER
    else if (message.command === 211) {
      missionItems.push({
        id: index,
        type: 'doGripper',
        data: {
          gripper: message.param1,
          action: message.param2,
        },
      });
    }
  });

  return missionItems;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { toMavLink, fromMavLink };
