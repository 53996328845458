import useMountEffect from '@restart/hooks/useMountEffect';
import React, { useState } from 'react';

import { Mode } from '@/components/ui/Dashboard';
import EventEmitter from '@/libs/EventEmitter';

const MODE = {
  0: 'Stabilize',
  1: 'Acro',
  2: 'AltHold',
  3: 'Auto',
  4: 'Guided',
  5: 'Loiter',
  6: 'RTL',
  7: 'Circle',
  9: 'Land',
  11: 'Drift',
  13: 'Sport',
  14: 'Flip',
  15: 'AutoTune',
  16: 'PosHold',
  17: 'Brake',
  18: 'Throw',
  19: 'Avoid ADSB',
  20: 'Guided NoGPS',
  21: 'Smart RTL',
  22: 'FlowHold',
  23: 'Follow',
  24: 'ZigZag',
  25: 'SystemID',
  26: 'Heli Autorotate',
  27: 'Auto RTL',
};

const Component = ({ robotId }) => {
  const [mode, setMode] = useState();

  useMountEffect(() => {
    const subscribeToken = EventEmitter.subscribe(`${robotId}/telemetry/heartbeat`, (data) => {
      setMode(MODE[data.mode]);
    });

    return () => {
      EventEmitter.unsubscribe(subscribeToken);
    };
  });

  return <Mode robotId={robotId} mode={mode} />;
};

export default Component;
