import useMountEffect from '@restart/hooks/useMountEffect';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { isElectron, isBrowser } from '@/environment';

export const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem('@token');

    if (token === null) {
      navigate('/', { replace: true });
    }
  }, [location.pathname]);

  return children;
};

export const UnprotectedRoute = ({ children }) => {
  const navigate = useNavigate();

  useMountEffect(() => {
    // Electron 실행 시
    if (isElectron) {
      localStorage.removeItem('@token');
    }

    // Browser 실행 시
    if (isBrowser) {
      const token = localStorage.getItem('@token');
      if (token !== null) {
        navigate('/control-center', { replace: true });
      }
    }
  });

  return children;
};
