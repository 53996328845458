import useMountEffect from '@restart/hooks/useMountEffect';
import React, { useState, useRef } from 'react';

import { setHandler } from './ModalService';

const ModalContainer = () => {
  const component = useRef();
  const props = useRef();
  const [_, setLastUpdated] = useState();

  useMountEffect(() => {
    setHandler((_component, _props) => {
      component.current = _component;
      props.current = _props;

      setLastUpdated(Date.now());
    });
  });

  if (!component.current) return;

  const Modal = component.current;

  return <Modal {...props.current} />;
};

export default ModalContainer;
