import classNames from 'classnames/bind';
import React from 'react';

import styles from './Bar.module.scss';

const cx = classNames.bind(styles);

const Bar = ({ height = 12 }) => {
  return <div className={cx('container')} style={{ height }} />;
};

export default Bar;
