import produce from 'immer';
import { handleActions } from 'redux-actions';

const initialState = null;

export default handleActions(
  {
    'USER/RESET': (state, action) => initialState,
    'USER/LOAD': (state, action) => action.payload.user,
    'USER/MODIFY': (state, action) =>
      produce(state, (draft) => {
        const { name, cellphone } = action.payload;
        draft.name = name;
        draft.cellphone = cellphone;
      }),
  },
  initialState
);
