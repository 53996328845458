import classNames from 'classnames/bind';
import React from 'react';

import styles from './DoChangeSpeed.module.scss';

const cx = classNames.bind(styles);

const Item = ({ data: missionItem }) => {
  return (
    <div className={cx('container')}>
      <label>
        Speed
        <div className={cx('fieldWrapper')}>
          <input value={missionItem.data.speed.toFixed(1)} readOnly />
          <span className={cx('unit')}>m/s</span>
        </div>
      </label>
    </div>
  );
};

export default Item;
