import produce from 'immer';
import { handleActions } from 'redux-actions';

const initialState = {};

export default handleActions(
  {
    'COMMAND_SET/LOAD': (state, action) =>
      produce(state, (draft) => {
        const { modelId, commandSet } = action.payload;
        draft[modelId] = commandSet;
      }),
  },
  initialState
);
