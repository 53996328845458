import classNames from 'classnames/bind';
import React from 'react';

import styles from './Mode.module.scss';

import useWaitingRobot from '@/hooks/useWaitingRobot';

const cx = classNames.bind(styles);

const Mode = ({ robotId, mode }) => {
  const [isWaiting] = useWaitingRobot(robotId);

  return (
    <div className={cx('container')}>
      {isWaiting && (
        <div className={cx('waiting')}>
          Waiting<span className={cx('dots')}></span>
        </div>
      )}
      {!isWaiting && mode}
    </div>
  );
};

export default Mode;
