import { handleActions } from 'redux-actions';

const initialState = [];

export default handleActions(
  {
    'LICENSE/RESET': (state, action) => initialState,
    'LICENSE/LOAD': (state, action) => action.payload.licenses,
  },
  initialState
);
