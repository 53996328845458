import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import { RiCheckboxBlankCircleLine, RiCheckboxCircleFill, RiDeleteBinLine } from 'react-icons/ri';

import styles from './Checklist.module.scss';
import EmptyList from '../ui/EmptyList';
import ModalSpinner from '../ui/ModalSpinner';

import Checkbox from '@/components/ui/Checkbox';
import ModalWrapper from '@/components/ui/Modal';
import API from '@/helpers/API';
import { ModalService as modal } from '@/libs/Modal';
import { ToastService as toast } from '@/libs/Toast';

const cx = classNames.bind(styles);

const Modal = ({ robotId }) => {
  const questionRef = useRef();
  const [checklist, setChecklist] = useState();
  const [isEditMode, setIsEditMode] = useState(false);

  useMountEffect(() => {
    API.get(`/robots/${robotId}/checklist`).then(({ success, data }) => {
      if (success) {
        const nextChecklist = data.map((item) => ({ ...item, isChecked: false }));
        setChecklist(nextChecklist);
      }
    });
  });

  const close = () => {
    modal.hide();
  };

  const check = (index) => {
    const nextChecklist = [...checklist];
    nextChecklist[index].isChecked = !checklist[index].isChecked;
    setChecklist(nextChecklist);
  };

  const doAdd = () => {
    const question = questionRef.current.value.trim();
    if (question === '') {
      toast.error('Please enter a question.');
      return;
    }

    API.post(`/robots/${robotId}/checklist`, { question }).then(({ success, data }) => {
      if (success) {
        setChecklist([...checklist, { ...data, isChecked: false }]);
        questionRef.current.value = '';
      }
    });
  };

  const doDelete = (e) => {
    const { id } = e.currentTarget.dataset;

    API.delete(`/robots/${robotId}/checklist/${id}`).then(({ success }) => {
      if (success) {
        setChecklist(checklist.filter((item) => item.id !== id));
      }
    });
  };

  const confirm = () => {
    const isAllChecked = checklist.every(({ isChecked }) => isChecked);
    if (!isAllChecked) {
      toast.error('Please check all items.');
      return;
    }

    const storeKey = `@preventChecklist/${robotId}`;
    localStorage.setItem(storeKey, moment().format('YYYYMMDD'));
    close();
  };

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  return (
    <ModalWrapper>
      <div className={cx('wrapper')}>
        <div className={cx('table')}>
          <div className={cx('header')}>
            <div className={cx(['column', 'no'])}>No</div>
            <div className={cx(['column', 'question'])}>Question</div>
            {!isEditMode && <div className={cx(['column', 'check'])}>Check</div>}
            {isEditMode && <div className={cx(['column', 'control'])}>Delete</div>}
          </div>
          <div className={cx(['body', { editMode: isEditMode }])}>
            {checklist === undefined && <ModalSpinner />}
            {checklist?.length === 0 && (
              <div className={cx('empty')}>
                <EmptyList />
              </div>
            )}
            {checklist?.map((item, index) => (
              <div key={index} className={cx('row')}>
                <div className={cx(['column', 'no'])}>{index + 1}</div>
                <div className={cx(['column', 'question'])}>{item.question}</div>
                {!isEditMode && (
                  <div className={cx(['column', 'check'])}>
                    {item.isChecked ? (
                      <RiCheckboxCircleFill size={20} color="#41a3ff" onClick={() => check(index)} />
                    ) : (
                      <RiCheckboxBlankCircleLine size={20} color="white" onClick={() => check(index)} />
                    )}
                  </div>
                )}
                {isEditMode && (
                  <div className={cx(['column', 'control'])}>
                    <button type="button" className={cx('button')} data-id={item.id} onClick={doDelete}>
                      <RiDeleteBinLine size={14} color="white" />
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        {isEditMode && (
          <div className={cx('form')}>
            <input ref={questionRef} type="text" placeholder="Please enter the new question." />
            <button type="button" className={cx('button')} onClick={doAdd}>
              Add
            </button>
          </div>
        )}
      </div>
      <div className={cx('bottom')}>
        <button type="button" className={cx('button')} onClick={close}>
          Cancel
        </button>
        <button type="button" className={cx(['button', 'accent'])} onClick={confirm}>
          Confirm
        </button>
        <div className={cx('checkbox')}>
          <Checkbox checked={isEditMode} size={16} onClick={toggleEditMode} />
          Edit Mode
        </div>
      </div>
    </ModalWrapper>
  );
};

export default Modal;
