import { useEffect } from 'react';

import EventEmitter from '@/libs/EventEmitter';

const useWaitingRobotTimer = (robotId, dependencies, timeout = 5000) => {
  useEffect(() => {
    const waitingTimer = setTimeout(() => {
      EventEmitter.publish(`${robotId}/event/waitingRobot`);
    }, timeout);

    // 10분 후 연결 해제
    const disconnectTimer = setTimeout(() => {
      EventEmitter.publish(`${robotId}/event/disconnectRobot`);
    }, 600 * 1000);

    return () => {
      EventEmitter.publish(`${robotId}/event/reconnectRobot`);
      clearTimeout(waitingTimer);
      clearTimeout(disconnectTimer);
    };
  }, dependencies);
};

export default useWaitingRobotTimer;
