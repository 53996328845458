import M1Tracker from '@/models/iot-m1-tracker';
import WFVertiFit from '@/models/iot-weflo-vertifit';
import ArduCopter from '@/models/mavlink2-ardupilot-copter';
import ArduFW from '@/models/mavlink2-ardupilot-fw';
import ArduVTOL from '@/models/mavlink2-ardupilot-vtol';
import PX4Moonboat from '@/models/mavlink2-px4-moonboat';
import PX4Sheco from '@/models/mavlink2-px4-sheco';
import QTCopter from '@/models/mavlink2-quaternion-copter';
import UnitreeB2 from '@/models/ros2-unitree-b2';

const MODELS = {
  // MobilityOne Tracker
  '3da2a623-9b10-45e6-be97-79d68be23339': M1Tracker,
  // ArduPilot Copter
  '0ae6489b-7fd4-4d9d-8b47-a8c6c7e669ca': ArduCopter,
  // ArduPilot Fixed-wing
  '1a90a54a-e98c-4b26-89db-8f7b94fc065c': ArduFW,
  // ArduPilot VTOL
  '85570cb9-64ee-46b8-b612-d8b07813d5ef': ArduVTOL,
  // PX4 Moonboat
  '04460d4f-7139-4a4f-9971-921d7a05e764': PX4Moonboat,
  // PX4 Sheco
  'd212a420-d77f-4b32-baf2-0e5095393037': PX4Sheco,
  // Quaternion Copter
  'b5e665d7-dab4-41f0-84ca-df565269a256': QTCopter,
  // Unitree B2
  'a1dd3fee-3a72-4d33-9079-2aa73918cc39': UnitreeB2,
  // Weflo VERTI-FIT
  'db445e8f-d9f0-419c-b5f4-02a194747bcb': WFVertiFit,
};

export default MODELS;
