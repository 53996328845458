import { combineReducers } from 'redux';

import callout from './callout';
import command from './command';
import commandSet from './commandSet';
import editor from './editor';
import event from './event';
import licenses from './licenses';
import map from './map';
import mission from './mission';
import notification from './notification';
import robot from './robot';
import telemetry from './telemetry';
import user from './user';

const rootReducer = combineReducers({
  callout,
  command,
  commandSet,
  editor,
  event,
  licenses,
  map,
  mission,
  notification,
  robot,
  telemetry,
  user,
});

export default rootReducer;
