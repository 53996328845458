const MAPS = {
  GOOGLE: {
    name: 'Google',
    label: null,
    types: [
      { key: 'y', name: 'Satellite' },
      { key: 'm', name: 'Roadmap' },
      { key: 'p', name: 'Terrain' },
    ],
  },
  BING: {
    name: 'Bing',
    label: null,
    types: [
      { key: 'Aerial',                   name: 'Aerial' },
      { key: 'AerialWithLabelsOnDemand', name: 'Aerial w/Labels' },
      { key: 'Road',                     name: 'Road' },
      { key: 'CanvasDark',               name: 'Canvas Dark' },
      { key: 'CanvasLight',              name: 'Canvas Light' },
      { key: 'CanvasGray',               name: 'Canvas Gray' },
    ],
  },
  VWORLD: {
    name: 'VWorld',
    label: 'S. Korea Only',
    types: [
      { key: 'Satellite', name: 'Satellite' },
      { key: 'Base',      name: 'Base' },
      { key: 'white',     name: 'White' },
      { key: 'midnight',  name: 'Midnight' },
    ],
  },
};

export default MAPS;
