import classNames from 'classnames/bind';
import React from 'react';

import styles from './index.module.scss';
import MapSelector from './MapSelector';
import ZonesSelector from './ZonesSelector';

import Bar from '@/components/ui/Bar';

const cx = classNames.bind(styles);

const Selectors = () => {
  return (
    <div className={cx('container')}>
      <MapSelector />
      {process.env.REACT_APP_USE_VWORLD_ZONE === 'true' && <Bar />}
      {process.env.REACT_APP_USE_VWORLD_ZONE === 'true' && <ZonesSelector />}
    </div>
  );
};

export default Selectors;
