import EventEmitter3 from 'eventemitter3';

class EventEmitter {
  constructor() {
    if (EventEmitter.instance) {
      return EventEmitter.instance;
    }

    this.emitter = new EventEmitter3();
    this.tokenMap = new Map();

    EventEmitter.instance = this;
  }

  static publish(event, data) {
    if (!EventEmitter.instance) {
      new EventEmitter();
    }
    EventEmitter.instance.emitter.emit(event, data);
  }

  static subscribe(event, callback) {
    if (!EventEmitter.instance) {
      new EventEmitter();
    }
    const token = Symbol();
    EventEmitter.instance.emitter.on(event, callback);
    EventEmitter.instance.tokenMap.set(token, { event, callback });
    return token;
  }

  static unsubscribe(token) {
    if (EventEmitter.instance) {
      const subscription = EventEmitter.instance.tokenMap.get(token);
      if (subscription) {
        const { event, callback } = subscription;
        EventEmitter.instance.emitter.off(event, callback);
        EventEmitter.instance.tokenMap.delete(token);
      }
    }
  }
}

export default EventEmitter;
