import useMountEffect from '@restart/hooks/useMountEffect';
import { useNavigate } from 'react-router-dom';

import { isElectron, isBrowser } from '@/environment';

const Page = () => {
  const navigate = useNavigate();

  useMountEffect(() => {
    if (isElectron) {
      navigate('/connect', { replace: true });
    }
    if (isBrowser) {
      navigate('/login', { replace: true });
    }
  });

  return null;
};

export default Page;
