import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import { fromLonLat } from 'ol/proj';
import React, { useRef, useMemo } from 'react';
import { createRoot } from 'react-dom/client';
import { useSelector } from 'react-redux';

import Waypoint from '@/components/ui/map/Waypoint';
import { getMissionItemForSurveySegment } from '@/helpers/MissionConverter';
import OlMap from '@/helpers/OlMap';

const Waypoints = () => {
  const missionId = useSelector((state) => state.editor.id);
  const missionItems = useSelector((state) => state.editor.missionItems);
  const waypointsRef = useRef([]);
  const map = OlMap.getMap();

  const waypoints = useMemo(() => {
    const draftWaypoints = [];
    missionItems.forEach((missionItem) => {
      switch (missionItem.type) {
        case 'navLand':
        case 'navLoiterToAlt':
        case 'navTakeoff':
        case 'navWaypoint':
          draftWaypoints.push(missionItem);
          break;

        case 'navReturnToLaunch':
          draftWaypoints.push({ ...missionItem, data: missionItems[0].data });
          break;

        case 'cusSurvey':
          {
            const surveyMissionItem = getMissionItemForSurveySegment(missionItem);
            surveyMissionItem.data.positions.forEach((position, index) => {
              let id;
              // 서베이 시작점
              if (index === 0) {
                id = `${missionItem.id}/start`;
              }
              // 서베이 종료점
              else if (index === surveyMissionItem.data.positions.length - 1) {
                id = `${missionItem.id}/end`;
              }

              if (id) {
                draftWaypoints.push({
                  id,
                  type: 'cusSurvey',
                  data: { position },
                });
              }
            });
          }
          break;

        default:
          break;
      }
    });
    return draftWaypoints;
  }, [missionItems]);

  useUpdateEffect(() => {
    waypointsRef.current.forEach(({ marker }) => map.removeOverlay(marker));
    waypointsRef.current = [];

    const positions = [];
    missionItems.forEach(({ type, data }) => {
      switch (type) {
        case 'navLand':
        case 'navLoiterToAlt':
        case 'navTakeoff':
        case 'navWaypoint':
          positions.push([data.position.lng, data.position.lat]);
          break;

        case 'cusSurvey':
          data.positions.forEach((position) => positions.push([position.lng, position.lat]));
          break;

        default:
          break;
      }
    });
    OlMap.fitBounds(positions);
  }, [missionId]);

  useUpdateEffect(() => {
    const currWaypointIds = waypointsRef.current.map(({ id }) => id);
    const nextWaypointIds = waypoints.map(({ id }) => id);

    // 경로점 존재하지 않는 경우 (e.g. Reset)
    if (nextWaypointIds.length === 0) {
      waypointsRef.current.forEach(({ marker }) => map.removeOverlay(marker));
      waypointsRef.current = [];
    }
    // 경로점 추가
    else if (waypoints.length > waypointsRef.current.length) {
      waypoints.forEach((waypoint, index) => {
        if (!currWaypointIds.includes(waypoint.id)) {
          const marker = getMarkerElement(index, waypoint);
          waypointsRef.current.splice(index, 0, { ...waypoint, marker });

          // 다음 경로점 라벨 변경
          updateLabelsFrom(index + 1);
        }
      });
    }
    // 경로점 제거
    else if (waypoints.length < waypointsRef.current.length) {
      waypointsRef.current.forEach((waypoint, index) => {
        if (!nextWaypointIds.includes(waypoint.id)) {
          map.removeOverlay(waypoint.marker);
          waypointsRef.current.splice(index, 1);

          // 서베이 경우 서베이 종료점 추가 제거
          if (waypoint.type === 'cusSurvey') {
            map.removeOverlay(waypointsRef.current[index].marker);
            waypointsRef.current.splice(index, 1);
          }

          // 다음 경로점 라벨 변경
          updateLabelsFrom(index);
        }
      });
    }
    // 경로점 추가 및 제거 외
    else if (waypoints.length === waypointsRef.current.length) {
      waypointsRef.current.forEach((waypoint, index) => {
        const found = waypoints.find(({ id }) => id === waypoint.id);
        if (found === undefined) return;

        const foundPosition = fromLonLat([found.data.position.lng, found.data.position.lat]);
        const markerPosition = waypoint.marker.getPosition();
        // 위치 이동된 경우
        if (foundPosition[0] !== markerPosition[0] || foundPosition[1] !== markerPosition[1]) {
          waypoint.marker.setPosition(foundPosition);
        }
        // 유형 또는 이름 변경된 경우
        else if (found.type !== waypoint.type) {
          map.removeOverlay(waypoint.marker);
          const marker = getMarkerElement(index, found);
          waypointsRef.current.splice(index, 1, { ...found, marker });
        }
      });
    }
  }, [missionItems]);

  const getMarkerElement = (index, waypoint) => {
    const element = document.createElement('div');
    let label;
    switch (waypoint.type) {
      case 'navReturnToLaunch':
        label = 'R';
        break;
      case 'navLand':
        label = 'L';
        break;
      case 'navTakeoff':
        label = 'T';
        break;
      default:
        label = index;
        break;
    }

    createRoot(element).render(<Waypoint color="#41a3ff" data={{ label, ...waypoint.data }} />);

    element.addEventListener('click', () => {
      map.dispatchEvent({ type: `mission/${waypoint.id.split('/')[0]}/click` });
    });
    element.addEventListener('mouseenter', () => {
      element.style.cursor = 'pointer';
    });
    element.addEventListener('mouseleave', () => {
      element.style.cursor = 'default';
    });

    const overlay = OlMap.createOverlay({
      element,
      position: fromLonLat([waypoint.data.position.lng, waypoint.data.position.lat]),
      positioning: 'bottom-center',
      insertFirst: false,
    });
    map.addOverlay(overlay);

    return overlay;
  };

  // 다음 경로점 라벨 변경
  const updateLabelsFrom = (index) => {
    for (let i = index; i < waypointsRef.current.length; i++) {
      map.removeOverlay(waypointsRef.current[i].marker);
      const marker = getMarkerElement(i, waypoints[i]);
      waypointsRef.current.splice(i, 1, { ...waypoints[i], marker });
    }
  };

  return null;
};

export default Waypoints;
