import {
  RiRemoteControlLine,
  RiShutDownLine,
  RiDragMoveLine,
  RiArrowRightUpLine,
  RiArrowGoBackLine,
  RiPauseLine,
  RiFolderOpenLine,
  RiUpload2Line,
  RiPlayCircleLine,
  RiIndeterminateCircleLine,
} from 'react-icons/ri';

// Action
import Arm from './01_Action/01_Arm';
import Disarm from './01_Action/02_Disarm';
import Control from './01_Action/03_Control';
import Move from './01_Action/04_Move';
import Goto from './01_Action/05_Goto';
import Return from './01_Action/06_Return';
import Brake from './01_Action/07_Brake';
// Mission
import Load from './02_Mission/01_Load';
import Upload from './02_Mission/02_Upload';
import Start from './02_Mission/03_Start';
import Resume from './02_Mission/04_Resume';
import Clear from './02_Mission/05_Clear';

const commands = [
  {
    name: 'Action',
    subcommands: [
      { name: 'Arm', icon: RiShutDownLine, component: Arm },
      { name: 'Disarm', icon: RiShutDownLine, component: Disarm },
      { name: 'Control', icon: RiRemoteControlLine, component: Control },
      { name: 'Move', icon: RiDragMoveLine, component: Move },
      { name: 'Goto', icon: RiArrowRightUpLine, component: Goto },
      { name: 'Return', icon: RiArrowGoBackLine, component: Return },
      { name: 'Brake', icon: RiPauseLine, component: Brake },
    ],
  },
  {
    name: 'Mission',
    subcommands: [
      { name: 'Load', icon: RiFolderOpenLine, component: Load },
      { name: 'Upload', icon: RiUpload2Line, component: Upload },
      { name: 'Start', icon: RiPlayCircleLine, component: Start },
      { name: 'Resume', icon: RiPlayCircleLine, component: Resume },
      { name: 'Clear', icon: RiIndeterminateCircleLine, component: Clear },
    ],
  },
];

export default commands;
