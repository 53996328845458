import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import classNames from 'classnames/bind';
import React, { useMemo, useState } from 'react';
import { MdOutlinePersonOutline, MdOutlineCheck, MdOutlineClose } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

import styles from './Frame.module.scss';

import actions from '@/actions';
import ColorPickerModal from '@/components/modals/ColorPicker';
import AccordionCaret from '@/components/ui/AccordionCaret';
import ConfirmSlider from '@/components/ui/ConfirmSlider';
import useWaitingRobot from '@/hooks/useWaitingRobot';
import { ModalService as modal } from '@/libs/Modal';

const cx = classNames.bind(styles);

const Frame = ({ robot, mode, status, controlBar, children }) => {
  const dispatch = useDispatch();
  const targetRobotIds = useSelector((state) => state.robot.targetRobotIds);
  const activeRobotIds = useSelector((state) => state.robot.activeRobotIds);
  const [isOpenBody, setIsOpenBody] = useState(false);
  const [isShowConfirm, setIsShowConfirm] = useState(false);
  const [isWaiting] = useWaitingRobot(robot.id);

  // 선택 여부
  const isTarget = useMemo(() => targetRobotIds.includes(robot.id), [targetRobotIds]);
  // 연결 여부
  const isActive = useMemo(() => activeRobotIds.includes(robot.id), [activeRobotIds]);
  // 선택 가능여부
  const isTargetable = useMemo(() => robot.isOwned && isActive, [robot, isActive]);

  useUpdateEffect(() => {
    // 로봇 연결 해제된 경우
    if (!isActive) {
      setIsOpenBody(false);
    }
  }, [activeRobotIds]);

  useUpdateEffect(() => {
    if (isTarget && isWaiting) {
      dispatch(actions.robot.toggle(robot.id));
    }
    if (!isWaiting) {
      setIsShowConfirm(false);
    }
  }, [isWaiting]);

  const showColorPicker = (e) => {
    e.stopPropagation();
    if (!robot.isOwned) return;

    modal.show(ColorPickerModal, { robot });
  };

  const toggleTarget = () => {
    if (isWaiting) return;
    if (!isActive) return;
    if (!isTargetable) return;

    dispatch(actions.robot.toggle(robot.id));
  };

  const toggleBody = (e) => {
    e.stopPropagation();
    if (!isActive) return;

    setIsOpenBody(!isOpenBody);
  };

  const toggleConfirm = () => {
    setIsShowConfirm(!isShowConfirm);
  };

  const doDisconnect = () => {
    dispatch(actions.event.remove(robot.id));
    dispatch(actions.mission.unload(robot.id));
    dispatch(actions.notification.remove(robot.id));
    dispatch(actions.robot.deactivate(robot.id));
    dispatch(actions.telemetry.remove(robot.id));
  };

  return (
    <div
      id={`dashboard/${robot.id}`}
      className={cx(['container', { targetable: !isWaiting && isTargetable }])}
      onClick={toggleTarget}>
      <div className={cx(['tab', { target: isTarget }])}>
        <div>
          <MdOutlineCheck size={20} color="white" />
        </div>
      </div>
      <div className={cx('box')}>
        <div
          className={cx(['color', { clickable: robot.isOwned }])}
          style={{ backgroundColor: robot.color }}
          onClick={showColorPicker}
        />
        {isActive && (
          <div className={cx('top')}>
            <div className={cx('info')}>
              <div className={cx('label')}>{robot.model.agentType}</div>
              {robot.isOwned && <MdOutlinePersonOutline size={16} color="white" title="Commandable" />}
            </div>
            {status}
          </div>
        )}
        <div className={cx('wrapper')}>
          <div className={cx(['thumbnail', { active: isActive }])}>
            <img src={robot.thumbnailUrl ?? robot.model.thumbnailUrl} width="100%" alt={robot.model.name} />
          </div>
          <div className={cx('info')}>
            <div className={cx(['name', { active: isActive }])}>{robot.name}</div>
            {isActive && mode}
          </div>
          {isActive && (
            <div onClick={toggleBody}>
              <AccordionCaret up={isOpenBody} size={32} />
            </div>
          )}
        </div>
        <div className={cx(['body', { open: isOpenBody }])}>
          {!isWaiting && children}
          {isWaiting && (
            <div className={cx('waiting')}>
              <div className={cx('title')}>Communication Lost</div>
              <div className={cx('message')}>
                Please wait for the reconnection,
                <br />
                or manually disconnect the robot.
              </div>
              {!isShowConfirm && (
                <div className={cx('button')} onClick={toggleConfirm}>
                  Disconnect
                </div>
              )}
              {isShowConfirm && (
                <div className={cx('sliderWrapper')}>
                  <ConfirmSlider width={172} height={40} onConfirm={doDisconnect} labelStyle={{ fontSize: 12 }} />
                  <div className={cx('cancel')} onClick={toggleConfirm}>
                    <MdOutlineClose color="white" size={16} />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        {isActive && controlBar && <div className={cx('bottom')}>{controlBar}</div>}
      </div>
    </div>
  );
};

export default Frame;
