import classNames from 'classnames/bind';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';

import styles from './common.module.scss';

import actions from '@/actions';
import { MessageContext } from '@/helpers/MessageProvider';

const cx = classNames.bind(styles);

const Action = ({ data: robots }) => {
  const dispatch = useDispatch();
  const { publishCommand } = useContext(MessageContext);

  const doCommand = (e) => {
    const value = e.currentTarget.dataset.commandValue;

    robots.forEach((robot) => {
      publishCommand(robot, 'param/set', [['COM_CTRL_AUTH', value, 6]]);
    });
    dispatch(actions.command.toggle());
  };

  return (
    <div className={cx('container')}>
      <div className={cx('buttons')}>
        <button type="button" data-command-value={0} className={cx('button')} onClick={doCommand}>
          OFF
        </button>
        <button type="button" data-command-value={1} className={cx('button')} onClick={doCommand}>
          ON
        </button>
      </div>
      <div className={cx('message')}>
        When Manual Control is 'ON',
        <br />
        the controller on the boat is deactivated.
      </div>
    </div>
  );
};

export default Action;
