import ArduPilot from './ArduPilot';
import PX4 from './PX4';

import { deepCopy } from '@/utils/ObjectUtils';

const converter = { ArduPilot, PX4 };

export default converter;

export const getMissionItemForSurveySegment = (missionItem) => {
  if (missionItem.type === 'cusSurvey' && missionItem.data.segment) {
    const newMissionItem = deepCopy(missionItem);

    // TODO: Binary Search 이용한 성능 개선
    newMissionItem.data.positions = missionItem.data.positions.filter(({ segment }) => {
      return segment === missionItem.data.segment.index;
    });
    return newMissionItem;
  }

  return missionItem;
};
