import { createActions } from 'redux-actions';

export default createActions(
  {
    RESET: () => ({}),
    LOAD: (user) => ({ user }),
    MODIFY: ({ name, cellphone }) => ({ name, cellphone }),
  },
  { prefix: 'USER' }
);
