import classNames from 'classnames/bind';
import React, { useMemo } from 'react';
import SVG from 'react-inlinesvg';
import { useSelector } from 'react-redux';

import styles from './RobotMarker.module.scss';

const cx = classNames.bind(styles);

const RobotMarker = ({ data: robot, hideDirection = false }) => {
  const targetRobotIds = useSelector((state) => state.robot.targetRobotIds);

  const scale = useMemo(() => {
    return targetRobotIds.includes(robot.id) ? 1.2 : 1;
  }, [targetRobotIds]);

  return (
    <div className={cx('container')} style={{ transform: `scale(${scale})` }}>
      <SVG
        src={robot.model.iconUrl}
        width="48"
        height="48"
        fill={robot.color}
        stroke="rgba(255, 255, 255, 0.5)"
        strokeWidth={8}
      />
      {!hideDirection && (
        <div className={cx('header')}>
          <div className={cx('line')} />
          <div className={cx('arrow')} />
        </div>
      )}
    </div>
  );
};

export default RobotMarker;
