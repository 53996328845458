import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import classNames from 'classnames/bind';
import { fromLonLat } from 'ol/proj';
import React, { useRef, useState, useEffect } from 'react';
import { RiFocus3Line, RiSearchLine } from 'react-icons/ri';
import { useSelector } from 'react-redux';

import Bar from './Bar';
import styles from './Geo.module.scss';

import * as REGEX from '@/define/regExp';
import OlMap from '@/helpers/OlMap';
import { ToastService as toast } from '@/libs/Toast';
import { getMGRS, getFromMGRS, geocoding } from '@/utils/MapUtils';

const cx = classNames.bind(styles);

const FORM = {
  MGRS: 0,
  COORDINATES: 1,
  ADDRESS: 2,
};

const Geo = () => {
  const center = useSelector((state) => state.map.center);
  const position = useSelector((state) => state.map.position);
  const focusRobotId = useSelector((state) => state.robot.focusRobotId);
  const [mgrs, setMgrs] = useState('');
  const [activeForm, setActiveForm] = useState();
  const mgrsRef = useRef();
  const coordinatesRef = useRef();
  const addressRef = useRef();
  const map = OlMap.getMap();

  useEffect(() => {
    coordinatesRef.current.value = `${center.lat.toFixed(7)}, ${center.lng.toFixed(7)}`;
  }, [center]);

  useUpdateEffect(() => {
    const nextMGRS = getMGRS(position);
    mgrsRef.current.value = nextMGRS;
    setMgrs(nextMGRS);
  }, [position]);

  useUpdateEffect(() => {
    if (focusRobotId) {
      setActiveForm();
    }
  }, [focusRobotId]);

  const toggleForm = (e, key) => {
    // Form 클릭된 경우 이벤트 방지
    const closest = e.target.closest('[data-form]');
    if (closest) return;

    // Focus 설정된 경우 이벤트 방지
    if (focusRobotId) {
      toast.error('Please disable the robot focus.');
      return;
    }

    activeForm === key ? setActiveForm() : setActiveForm(key);

    setTimeout(() => {
      switch (key) {
        case FORM.MGRS:
          mgrsRef.current.focus();
          break;

        case FORM.COORDINATES:
          coordinatesRef.current.focus();
          break;

        case FORM.ADDRESS:
          addressRef.current.focus();
          break;

        default:
          break;
      }
    }, 100);
  };

  const changeMGRS = () => {
    if (mgrsRef.current.value.trim() === '') {
      toast.error('Please enter value for MGRS.');
      return;
    }

    try {
      const { lat, lng } = getFromMGRS(mgrsRef.current.value);
      moveMap(lat, lng);
    } catch (e) {
      toast.error('Please enter a value in MGRS format.');
    }
  };

  const changeCoordinates = () => {
    const [lat, lng] = coordinatesRef.current.value.split(', ');

    if (!REGEX.LATITUDE.test(lat) || !REGEX.LONGITUDE.test(lng)) {
      toast.error('Please check the latitude and longitude.');
      return;
    }
    moveMap(Number(lat), Number(lng));
  };

  const searchAddress = async () => {
    if (addressRef.current.value.trim() === '') {
      toast.error('Please enter value for address.');
      return;
    }

    try {
      const { lat, lng } = await geocoding({ address: addressRef.current.value });
      moveMap(lat, lng);
    } catch (e) {
      toast.error(e.message);
    }

    addressRef.current.value = '';
  };

  const moveMap = (lat, lng) => {
    map.getView().animate({
      center: fromLonLat([lng, lat]),
      duration: 300,
    });
  };

  const pressKey = (e) => {
    if (e.nativeEvent.isComposing) return;

    if (e.code === 'Enter') {
      switch (e.target.name) {
        case 'mgrs':
          changeMGRS();
          break;

        case 'coordinates':
          changeCoordinates();
          break;

        case 'address':
          searchAddress();
          break;

        default:
          break;
      }
      setActiveForm();
    } else if (e.code === 'Escape') {
      setActiveForm();
    }
  };

  return (
    <div className={cx('container')}>
      <div className={cx('term')} onClick={(e) => toggleForm(e, FORM.MGRS)}>
        <div className={cx('label')}>MGRS</div>
        <div className={cx('value')}>{mgrs}</div>
        <div data-form className={cx(['panel', { show: activeForm === FORM.MGRS }])}>
          <input
            ref={mgrsRef}
            name="mgrs"
            type="text"
            placeholder="MGRS"
            onKeyDown={pressKey}
            className={cx('field')}
          />
        </div>
      </div>
      <Bar />
      <div className={cx('term')} onClick={(e) => toggleForm(e, FORM.COORDINATES)}>
        <RiFocus3Line size={18} color="white" title="Latitude, Longitude" />
        <div className={cx('value')}>
          {center.lat.toFixed(7)}, {center.lng.toFixed(7)}
        </div>
        <div data-form className={cx(['panel', { show: activeForm === FORM.COORDINATES }])}>
          <input
            ref={coordinatesRef}
            name="coordinates"
            type="text"
            placeholder="Latitude, Longitude"
            onKeyDown={pressKey}
            className={cx('field')}
          />
        </div>
      </div>
      <Bar />
      <div className={cx('term')} onClick={(e) => toggleForm(e, FORM.ADDRESS)}>
        <RiSearchLine size={18} color="white" title="Address" />
        <div data-form className={cx(['panel', { show: activeForm === FORM.ADDRESS }])}>
          <input
            ref={addressRef}
            name="address"
            type="text"
            placeholder="Address Keyword"
            onKeyDown={pressKey}
            className={cx('field')}
          />
        </div>
      </div>
    </div>
  );
};

export default Geo;
