import classNames from 'classnames/bind';
import React from 'react';

import styles from './Cross.module.scss';

const cx = classNames.bind(styles);

const Cross = () => {
  return (
    <div className={cx('container')}>
      <div className={cx(['line', 'vertical'])} />
      <div className={cx(['line', 'horizontal'])} />
    </div>
  );
};

export default Cross;
