import classNames from 'classnames/bind';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import styles from './Logout.module.scss';

import actions from '@/actions';
import ModalWrapper from '@/components/ui/Modal';
import { removeAuthToken } from '@/helpers/API';
import { postLogout } from '@/helpers/Requester';
import { ModalService as modal } from '@/libs/Modal';

const cx = classNames.bind(styles);

const Modal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const close = () => {
    modal.hide();
  };

  const doLogout = () => {
    postLogout();
    removeAuthToken();
    navigate('/', { replace: true });

    dispatch(actions.command.reset());
    dispatch(actions.license.reset());
    dispatch(actions.event.reset());
    dispatch(actions.map.reset());
    dispatch(actions.mission.reset());
    dispatch(actions.notification.reset());
    dispatch(actions.robot.reset());
    dispatch(actions.telemetry.reset());
    dispatch(actions.user.reset());

    close();
  };

  return (
    <ModalWrapper>
      <div className={cx('message')}>Do you really want to logout?</div>
      <div className={cx('bottom')}>
        <button type="button" className={cx('button')} onClick={close}>
          Cancel
        </button>
        <button type="button" className={cx(['button', 'accent'])} onClick={doLogout}>
          Logout
        </button>
      </div>
    </ModalWrapper>
  );
};

export default Modal;
