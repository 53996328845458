import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import classNames from 'classnames/bind';
import { Draw, Modify } from 'ol/interaction';
import { Vector as VectorLayer } from 'ol/layer';
import { toLonLat } from 'ol/proj';
import { Vector as VectorSource } from 'ol/source';
import { Style, Stroke } from 'ol/style';
import React, { useRef, useState, useMemo } from 'react';
import { RiSketching } from 'react-icons/ri';
import { useDispatch, useSelector, useStore } from 'react-redux';

import styles from './index.module.scss';
import LineDrawer from './LineDrawer';

import actions from '@/actions';
import OlMap from '@/helpers/OlMap';
import { ToastService as toast } from '@/libs/Toast';
import CoordUtils from '@/utils/CoordUtils';

const cx = classNames.bind(styles);

const style = new Style({
  stroke: new Stroke({ color: 'black', width: 4 }),
});

const DrawLine = () => {
  const dispatch = useDispatch();
  const mode = useSelector((state) => state.editor.mode);
  const store = useStore();
  const [points, setPoints] = useState();
  const source = useRef();
  const interaction = useRef();
  const modify = useRef();
  const map = OlMap.getMap();

  const isActive = useMemo(() => {
    return mode === 'line';
  }, [mode]);

  useUpdateEffect(() => {
    if (!isActive) return;

    source.current = new VectorSource({ wrapX: false });
    const layer = new VectorLayer({
      source: source.current,
      style,
    });
    map.addLayer(layer);

    interaction.current = new Draw({
      source: source.current,
      type: 'LineString',
      freehand: true,
      style,
    });
    interaction.current.on('drawend', drawLine);
    map.addInteraction(interaction.current);

    return () => {
      map.removeLayer(layer);
      map.removeInteraction(interaction.current);
      interaction.current = null;

      holdModifying();
      setPoints();
    };
  }, [isActive]);

  const drawLine = (e) => {
    map.removeInteraction(interaction.current);
    interaction.current = null;

    modify.current = new Modify({ source: source.current });
    modify.current.on('modifyend', modifyLine);
    map.addInteraction(modify.current);

    const points = e.feature.getGeometry().getCoordinates();
    updatePoints(points);
  };

  const modifyLine = (e) => {
    const points = e.features.item(0).getGeometry().getCoordinates();
    updatePoints(points);
  };

  const updatePoints = (points) => {
    // 중복 좌표 제거
    const filtered = points.filter((point, index) => {
      const prev = JSON.stringify(point);
      const next = JSON.stringify(points[index + 1]);
      return prev !== next;
    });

    const nextPoints = filtered.map((point) => {
      return CoordUtils.objectFromArray(toLonLat(point));
    });
    setPoints(nextPoints);
  };

  const holdModifying = () => {
    map.removeInteraction(modify.current);
    modify.current = null;
  };

  const toggle = () => {
    if (store.getState().editor.missionItems.length === 0) {
      toast.error('Please add a takeoff point first.');
      return;
    }

    if (isActive) {
      dispatch(actions.editor.changeMode(null));
    } else {
      dispatch(actions.editor.changeMode('line'));
    }
  };

  return (
    <div className={cx('container')}>
      <div className={cx(['button', { active: isActive }])} onClick={toggle}>
        <RiSketching size={16} />
        <div className={cx('label')}>Line</div>
      </div>
      {isActive && <LineDrawer points={points} onCancelOrSubmit={toggle} />}
    </div>
  );
};

export default DrawLine;
