import classNames from 'classnames/bind';
import React, { useState } from 'react';

import Accelerometer from './01_Accelerometer';
import Compass from './02_Compass';
import LevelHorizon from './03_LevelHorizon';
import Gyroscope from './04_Gyroscope';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

const Calibration = ({ robot }) => {
  const [menuIndex, setMenuIndex] = useState();

  const toggleMenu = (index) => {
    if (menuIndex === index) {
      setMenuIndex();
    } else {
      setMenuIndex(index);
    }
  };

  return (
    <div className={cx('container')}>
      <div className={cx('menus')}>
        <div onClick={() => toggleMenu(0)} className={cx(['menu', { target: menuIndex === 0 }])}>
          Accelerometer
        </div>
        <div onClick={() => toggleMenu(1)} className={cx(['menu', { target: menuIndex === 1 }])}>
          Compass
        </div>
        <div onClick={() => toggleMenu(2)} className={cx(['menu', { target: menuIndex === 2 }])}>
          Level Horizon
        </div>
        <div onClick={() => toggleMenu(3)} className={cx(['menu', { target: menuIndex === 3 }])}>
          Gyroscope
        </div>
      </div>
      <div className={cx('console')}>
        {isNaN(menuIndex) && <div className={cx('message')}>Start each calibration step.</div>}
        {menuIndex === 0 && <Accelerometer robot={robot} />}
        {menuIndex === 1 && <Compass robot={robot} />}
        {menuIndex === 2 && <LevelHorizon robot={robot} />}
        {menuIndex === 3 && <Gyroscope robot={robot} />}
      </div>
    </div>
  );
};

export default Calibration;
