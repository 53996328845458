const CoordUtils = {
  arrayFromObject({ lat, lng }) {
    return [lng, lat];
  },

  objectFromArray([lng, lat]) {
    return { lat, lng };
  },
};

export default CoordUtils;
