import produce from 'immer';
import { handleActions } from 'redux-actions';

const initialState = {
  enableCommandId: null,
};

export default handleActions(
  {
    'COMMAND/RESET': (state, action) => initialState,
    'COMMAND/TOGGLE': (state, action) =>
      produce(state, (draft) => {
        const { commandId } = action.payload;

        if (state.enableCommandId === commandId) {
          draft.enableCommandId = null;
        } else {
          draft.enableCommandId = commandId;
        }
      }),
  },
  initialState
);
