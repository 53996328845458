import classNames from 'classnames/bind';
import React from 'react';
import {
  MdOutlineSatelliteAlt,
  MdOutlineDeviceThermostat,
  MdOutlineBattery0Bar,
  MdOutlineBattery1Bar,
  MdOutlineBattery2Bar,
  MdOutlineBattery3Bar,
  MdOutlineBattery4Bar,
  MdOutlineBattery5Bar,
  MdOutlineBattery6Bar,
  MdOutlineBatteryFull,
  MdOutlineBolt,
} from 'react-icons/md';

import styles from './Status.module.scss';

const cx = classNames.bind(styles);

const Status = ({ data: status }) => {
  const getBatteryIcon = (percentage) => {
    if (percentage > 98) {
      return MdOutlineBatteryFull;
    } else if (percentage > 85) {
      return MdOutlineBattery6Bar;
    } else if (percentage > 70) {
      return MdOutlineBattery5Bar;
    } else if (percentage > 55) {
      return MdOutlineBattery4Bar;
    } else if (percentage > 40) {
      return MdOutlineBattery3Bar;
    } else if (percentage > 25) {
      return MdOutlineBattery2Bar;
    } else if (percentage > 10) {
      return MdOutlineBattery1Bar;
    } else {
      return MdOutlineBattery0Bar;
    }
  };

  return (
    <div className={cx('container')}>
      {Object.entries(status).map(([key, value], index) => {
        // 위성 수
        if (key === 'satellites') {
          const blink = value !== null && value < 10;

          return (
            <div key={index} className={cx(['item', { blink }])} style={{ gap: 4 }}>
              <MdOutlineSatelliteAlt size={16} />
              <div className={cx('value')}>{value ?? '-'}</div>
            </div>
          );
        }
        // 배터리 온도
        else if (key === 'temperature') {
          const temperature = value ? `${value.toFixed(1)}℃` : '-';

          return (
            <div key={index} className={cx('item')}>
              <MdOutlineDeviceThermostat size={16} />
              <div className={cx('value')}>{temperature}</div>
            </div>
          );
        }
        // 배터리 잔량
        else if (key === 'percentage') {
          const BatteryIcon = getBatteryIcon(value);
          const blink = value !== null && value < 30;
          const percentage = value ? `${value.toFixed(0)}%` : '-';

          return (
            <div key={index} className={cx(['item', { blink }])}>
              <BatteryIcon size={16} />
              <div className={cx('value')}>{percentage}</div>
            </div>
          );
        }
        // 배터리 전압
        else if (key === 'voltage') {
          const voltage = value ? `${value.toFixed(1)}V` : '-';

          return (
            <div key={index} className={cx('item')}>
              <MdOutlineBolt size={16} />
              <div className={cx('value')}>{voltage}</div>
            </div>
          );
        }
        // 배터리 전류
        else if (key === 'current') {
          const current = value ? `${value.toFixed(1)}A` : '-';

          return (
            <div key={index} className={cx('item')}>
              <MdOutlineBolt size={16} />
              <div className={cx('value')}>{current}</div>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

export default Status;
