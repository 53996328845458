import useMountEffect from '@restart/hooks/useMountEffect';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import { distance } from '@turf/turf';
import { pointerMove } from 'ol/events/condition';
import { Select } from 'ol/interaction';
import { toLonLat } from 'ol/proj';
import { useContext, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import actions from '@/actions';
import { MessageContext } from '@/helpers/MessageProvider';
import MissionConverter from '@/helpers/MissionConverter';
import OlMap from '@/helpers/OlMap';
import EventEmitter from '@/libs/EventEmitter';

export const ArduPilot = Symbol();
export const PX4 = Symbol();

const useMission = (firmware, robot) => {
  const dispatch = useDispatch();
  const { publishCommand } = useContext(MessageContext);
  const mission = useSelector((state) => state.mission[robot.id]);
  const prepared = useRef([]);
  const homePosition = useRef();
  const missionItems = useRef();
  const map = OlMap.getMap();

  useMountEffect(() => {
    // 제어권한 미보유 시
    if (!robot.isOwned) return;

    publishCommand(robot, 'mission/request_list', [[]]);

    const subscribeTokens = [];
    subscribeTokens.push(
      EventEmitter.subscribe(`${robot.id}/telemetry/missionCount`, ({ count }) => {
        prepared.current = new Array(count).fill(null);

        // 미션 항목 존재 시
        if (count > 0) {
          // 첫 번째 미션 항목 요청
          publishCommand(robot, 'mission/request_item', [[0]]);
        }
      })
    );
    subscribeTokens.push(
      EventEmitter.subscribe(`${robot.id}/telemetry/missionItem`, (missionItem) => {
        // 미션 미정의 시
        if (prepared.current.length === 0) return;
        // 미션 항목 완성일 시
        if (prepared.current[missionItem.seq] !== null) return;

        prepared.current[missionItem.seq] = missionItem;

        const emptyIndex = prepared.current.findIndex((item) => item === null);
        // 미정의 미션 항목 요청
        if (emptyIndex > -1) {
          publishCommand(robot, 'mission/request_item', [[emptyIndex]]);
        }
        // 모든 미션 항목 정의 시
        else {
          let converter;
          switch (firmware) {
            case ArduPilot:
              converter = MissionConverter.ArduPilot;
              break;

            case PX4:
              converter = MissionConverter.PX4;
              break;

            default:
              break;
          }

          const nextMissionItems = converter.fromMavLink(prepared.current);
          dispatch(actions.mission.load(robot.id, nextMissionItems));
        }
      })
    );
    subscribeTokens.push(
      EventEmitter.subscribe(`${robot.id}/telemetry/homePosition`, (position) => {
        // Home position 변경된 경우
        if (JSON.stringify(homePosition.current) !== JSON.stringify(position)) {
          homePosition.current = position;

          OlMap.removeMission(robot.id);
          drawMission();
        }
      })
    );

    return () => {
      subscribeTokens.forEach((subscribeToken) => EventEmitter.unsubscribe(subscribeToken));
    };
  });

  useUpdateEffect(() => {
    missionItems.current = mission;
    drawMission();

    return () => {
      OlMap.removeMission(robot.id);
    };
  }, [mission, robot.color]);

  const drawMission = () => {
    if (!homePosition.current) return;
    if (!missionItems.current) return;

    // 마커 정의
    const markers = [];
    missionItems.current.forEach((missionItem, index) => {
      switch (missionItem.type) {
        case 'navLand':
        case 'navLoiterToAlt':
        case 'navTakeoff':
        case 'navWaypoint':
          markers.push({
            position: missionItem.data.position,
            label: index + 1,
          });
          break;

        case 'navReturnToLaunch':
          markers.push({
            position: homePosition.current,
            label: index + 1,
          });
          break;

        default:
          break;
      }
    });
    const { paths } = OlMap.addMission(robot.id, markers, robot.color);

    // Mouse over 시 강조
    const handleHover = new Select({
      condition: pointerMove,
      layers: paths,
    });
    handleHover.on('select', (e) => {
      if (e.selected.length) {
        const { flatCoordinates } = e.selected[0].values_.geometry;
        const distanceValue =
          1000 *
          distance(
            toLonLat([flatCoordinates[0], flatCoordinates[1]]),
            toLonLat([flatCoordinates[2], flatCoordinates[3]])
          );

        dispatch(actions.callout.setText(`${distanceValue.toFixed(1)}m`));
      } else {
        dispatch(actions.callout.setText(null));
      }
    });
    map.addInteraction(handleHover);
  };
};

export default useMission;
