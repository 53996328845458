import classNames from 'classnames/bind';
import React, { useMemo } from 'react';

import styles from './DoGripper.module.scss';

const cx = classNames.bind(styles);

const Item = ({ data: missionItem }) => {
  const action = useMemo(() => {
    switch (missionItem.data.action) {
      case 0:
        return 'Release';
      case 1:
        return 'Grab';
      default:
        break;
    }
  }, [missionItem]);

  return (
    <div className={cx('container')}>
      <label>
        Gripper Instance Number
        <div className={cx('fieldWrapper')}>
          <input value={missionItem.data.gripper} readOnly />
        </div>
      </label>
      <label>
        Action
        <div className={cx('fieldWrapper')}>
          <input value={action} readOnly />
        </div>
      </label>
    </div>
  );
};

export default Item;
