import callout from './callout';
import command from './command';
import commandSet from './commandSet';
import editor from './editor';
import event from './event';
import license from './license';
import map from './map';
import mission from './mission';
import notification from './notification';
import robot from './robot';
import telemetry from './telemetry';
import user from './user';

const actions = {
  callout,
  command,
  commandSet,
  editor,
  event,
  license,
  map,
  mission,
  notification,
  robot,
  telemetry,
  user,
};

export default actions;
