import classNames from 'classnames/bind';
import React from 'react';
import { Component } from 'react';

import styles from './ErrorBoundary.module.scss';

const cx = classNames.bind(styles);

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error) {
    console.error(error);
  }

  reload() {
    window.location.replace('/');
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={cx('container')}>
          <div className={cx('form')}>
            <div className={cx('message')}>
              An unexpected error occurred.
              <br />
              <span className={cx('accent')}>Please reload the page again.</span>
              <br />
              If the problem persists, please contact the administrator.
            </div>
            <button type="button" className={cx(['button', 'accent'])} onClick={this.reload}>
              Reload
            </button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
