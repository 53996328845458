import classNames from 'classnames/bind';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import styles from './index.module.scss';
import Panel from './Panel';

const cx = classNames.bind(styles);

const Videos = () => {
  const robots = useSelector((state) => state.robot.robots);
  const options = useSelector((state) => state.robot.options);

  const filteredRobots = useMemo(() => {
    const robotIds = Object.keys(options).filter((key) => options[key].video);
    return robots.filter((robot) => robotIds.includes(robot.id));
  }, [robots, options]);

  return (
    <div className={cx('container')}>
      {filteredRobots.map((robot) => (
        <Panel key={robot.id} data={robot} />
      ))}
    </div>
  );
};

export default Videos;
