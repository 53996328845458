import useMountEffect from '@restart/hooks/useMountEffect';
import { useNavigate, useSearchParams } from 'react-router-dom';

import API, { setAuthToken } from '@/helpers/API';

const Page = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useMountEffect(() => {
    const ticket = searchParams.get('ticket') ?? '';

    API.post('/auth/autologin', { ticket }).then(({ success, data }) => {
      if (success && data.accessToken) {
        setAuthToken(data.accessToken);
        navigate('/', { replace: true });
      }
    });
  });

  return null;
};

export default Page;
