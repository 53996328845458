import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import classNames from 'classnames/bind';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Controller from './Controller';
import styles from './index.module.scss';

import actions from '@/actions';
import MODELS from '@/models';

const cx = classNames.bind(styles);

const Command = () => {
  const dispatch = useDispatch();
  const robots = useSelector((state) => state.robot.robots);
  const targetRobotIds = useSelector((state) => state.robot.targetRobotIds);
  const enableCommandId = useSelector((state) => state.command.enableCommandId);

  const modelId = useMemo(() => {
    if (!targetRobotIds.length === 0) return;

    const modelIds = robots.filter((robot) => targetRobotIds.includes(robot.id)).map((robot) => robot.model.id);
    const uniqueModelIds = [...new Set(modelIds)];
    if (uniqueModelIds.length > 1) return;

    return uniqueModelIds[0];
  }, [targetRobotIds]);

  useUpdateEffect(() => {
    if (!enableCommandId) return;

    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [enableCommandId]);

  const onKeyDown = (e) => {
    // Enter, Space 키 입력에 의한 클릭 효과 방지
    if (['Enter', 'Space'].includes(e.code)) {
      e.preventDefault();
    }
    // ESC 키 입력 시 닫기
    if (e.code === 'Escape') {
      dispatch(actions.command.toggle(enableCommandId));
    }
  };

  const toggle = (e) => {
    dispatch(actions.command.toggle(e.currentTarget.dataset.commandId));
  };

  return (
    <div className={cx('container')}>
      <Controller />
      <div className={cx('groups')}>
        {MODELS[modelId]?.commands?.map((command) => (
          <div key={command.name} className={cx('group')}>
            <div className={cx('name')}>{command.name}</div>
            <div className={cx('buttons')}>
              {command.subcommands.map((subcommand) => {
                const commandId = `${modelId}/${command.name}/${subcommand.name}`;
                const pressed = commandId === enableCommandId;

                return (
                  <button
                    key={subcommand.name}
                    type="button"
                    data-command-id={commandId}
                    className={cx(['button', { pressed }])}
                    onClick={toggle}>
                    <subcommand.icon size={24} color="white" />
                    <div className={cx('label')}>{subcommand.name}</div>
                  </button>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Command;
