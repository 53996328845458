import classNames from 'classnames/bind';
import React, { useMemo } from 'react';

import styles from './DoParachute.module.scss';

const cx = classNames.bind(styles);

const Item = ({ data: missionItem }) => {
  const message = useMemo(() => {
    switch (missionItem.data.action) {
      case 0:
        return 'Disable auto-release of parachute.';
      case 1:
        return 'Enable auto-release of parachute.';
      case 2:
        return 'Release parachute and kill motors.';
      default:
        return;
    }
  }, [missionItem.data.action]);

  return (
    <div className={cx('container')}>
      <div className={cx('message')}>{message}</div>
    </div>
  );
};

export default Item;
