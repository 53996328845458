import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

import styles from './Callout.module.scss';

const cx = classNames.bind(styles);

const Callout = () => {
  const { text } = useSelector((state) => state.callout);
  const containerRef = useRef();

  useMountEffect(() => {
    const handleMouseMove = (event) => {
      containerRef.current.style.left = `${event.clientX + 8}px`;
      containerRef.current.style.top = `${event.clientY + 8}px`;
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  });

  return (
    <div ref={containerRef} className={cx(['container', { visible: text !== null }])}>
      <div className={cx('line')} />
      <div className={cx('text')}>{text}</div>
    </div>
  );
};

export default Callout;
