import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import classNames from 'classnames/bind';
import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './Notifications.module.scss';

import actions from '@/actions';
import EmptyList from '@/components/ui/EmptyList';
import EventEmitter from '@/libs/EventEmitter';

const cx = classNames.bind(styles);

const HOVER_ROW = {
  index: -1,
  isOverflow: false,
};

const Notifications = ({ robotId, open }) => {
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notification[robotId]);
  const [hoverRow, setHoverRow] = useState(HOVER_ROW);

  const timersRef = useRef({});

  useUpdateEffect(() => {
    notifications
      .filter(({ isOld }) => !isOld)
      .filter(({ id }) => !Object.hasOwn(timersRef.current, id))
      .forEach((notif) => {
        // 타이머 정의
        const timer = setTimeout(() => {
          dispatch(actions.notification.markAsOld(robotId, notif.id));
          delete timersRef.current[notif.id]; // 타이머 제거
        }, 5000);

        // 타이머 저장
        timersRef.current[notif.id] = timer;
      });

    const isAllOld = notifications.every(({ isOld }) => isOld);
    if (isAllOld) {
      EventEmitter.publish(`${robotId}/event/openNotifications`, false);
    }
  }, [notifications]);

  const updateHoverRow = (e, index) => {
    const textElement = e.currentTarget.querySelector('[data-text]');
    const isOverflow = textElement.clientWidth < textElement.scrollWidth;
    setHoverRow({ index, isOverflow });
  };

  return (
    <div className={cx(['container', { open }])}>
      {(!notifications || notifications.length === 0) && (
        <div className={cx('empty')}>
          <EmptyList message="No items" />
        </div>
      )}
      {notifications?.map((notif, index) => (
        <div
          key={index}
          className={cx(['row', { old: notif.isOld }])}
          onMouseEnter={(e) => updateHoverRow(e, index)}
          onMouseLeave={() => setHoverRow(HOVER_ROW)}>
          <div className={cx('textWrapper')}>
            <div
              data-text
              title={notif.text}
              className={cx(['text', { marquee: index === hoverRow.index && hoverRow.isOverflow }])}>
              {notif.text}
            </div>
          </div>
          <div className={cx('time')}>{notif.time}</div>
        </div>
      ))}
    </div>
  );
};

export default Notifications;
