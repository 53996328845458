import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import Feature from 'ol/Feature';
import MultiPoint from 'ol/geom/MultiPoint';
import MultiPolygon from 'ol/geom/MultiPolygon';
import { Vector as VectorLayer } from 'ol/layer';
import { fromLonLat } from 'ol/proj';
import { Vector as VectorSource } from 'ol/source';
import { Style, Stroke, Fill, Circle } from 'ol/style';
import { useRef } from 'react';
import { useSelector } from 'react-redux';

import OlMap from '@/helpers/OlMap';

const Shoots = () => {
  const isShowShoots = useSelector((state) => state.map.isShowShoots);
  const latestShoots = useSelector((state) => state.editor.shoots);
  const addedLayer = useRef();
  const map = OlMap.getMap();

  useUpdateEffect(() => {
    addedLayer.current?.setVisible(isShowShoots);
  }, [isShowShoots]);

  useUpdateEffect(() => {
    // 영역 제거
    map.removeLayer(addedLayer.current);

    // 영역 추가
    if (latestShoots.length === 0) {
      addedLayer.current = null;
      return;
    }

    // 촬영 영역
    const boundaries = latestShoots.map(({ boundary }) => boundary.map(({ lat, lng }) => fromLonLat([lng, lat])));
    const polygons = new Feature(new MultiPolygon([boundaries]));

    // 촬영 지점
    const positions = latestShoots.map(({ position }) => fromLonLat([position.lng, position.lat]));
    const points = new Feature(new MultiPoint(positions));

    const source = new VectorSource({ features: [polygons, points], wrapX: false });
    const layer = new VectorLayer({
      source,
      visible: isShowShoots,
      declutter: true,
      style: new Style({
        image: new Circle({
          radius: 2,
          fill: new Fill({ color: 'yellow' }),
        }),
        stroke: new Stroke({
          color: 'white',
          width: 1,
          lineDash: [2, 4], // [길이, 간격]
        }),
        fill: new Fill({ color: [0, 0, 0, 0.3] }),
      }),
    });

    map.addLayer(layer);
    addedLayer.current = layer;
  }, [latestShoots]);

  return null;
};

export default Shoots;
