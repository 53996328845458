import produce from 'immer';
import { handleActions } from 'redux-actions';

const initialState = {
  text: null,
};

export default handleActions(
  {
    'CALLOUT/SET_TEXT': (state, action) =>
      produce(state, (draft) => {
        const { text } = action.payload;
        draft.text = text;
      }),
  },
  initialState
);
