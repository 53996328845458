import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import React, { useRef, useMemo } from 'react';

import styles from './ImageViewer.module.scss';

import ModalWrapper from '@/components/ui/Modal';

const cx = classNames.bind(styles);

const WIDTH = 800;

const Modal = (image) => {
  const canvasRef = useRef();

  const scale = useMemo(() => {
    return WIDTH / image.width;
  }, [image]);

  const height = useMemo(() => {
    return image.height * scale;
  }, [scale]);

  useMountEffect(() => {
    const context = canvasRef.current.getContext('2d');

    const img = new Image();
    img.src = image.url;

    img.onload = () => {
      context.drawImage(img, 0, 0, WIDTH, height);

      image.polygons.forEach((polygon) => {
        drawPolygon(context, polygon);
        drawLabel(context, polygon.label, polygon.coordinates[0]);
      });
    };
  });

  // 다각형 그리기
  const drawPolygon = (context, polygon) => {
    context.beginPath();
    context.moveTo(polygon.coordinates[0][0] * scale, polygon.coordinates[0][1] * scale);

    for (let index = 1; index < polygon.coordinates.length; index++) {
      context.lineTo(polygon.coordinates[index][0] * scale, polygon.coordinates[index][1] * scale);
    }

    context.closePath();
    context.strokeStyle = 'red';
    context.lineWidth = 2;
    context.stroke();
  };

  // 라벨 그리기
  const drawLabel = (context, text, coordinate) => {
    context.font = '12px Noto Sans KR';
    context.fillStyle = 'red';
    context.fillText(text, coordinate[0] * scale, coordinate[1] * scale - 8);
  };

  return (
    <ModalWrapper>
      <div className={cx('wrapper')}>
        <div className={cx('header')}>
          <div className={cx('caption')}>{image.caption}</div>
          <div className={cx('timestamp')}>{image.timestamp}</div>
        </div>
        <canvas ref={canvasRef} id="canvas" width={WIDTH} height={height}></canvas>
      </div>
    </ModalWrapper>
  );
};

export default Modal;
