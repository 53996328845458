import { createActions } from 'redux-actions';

export default createActions(
  {
    RESET: () => ({}),
    LOAD: (robots) => ({ robots }),
    TOGGLE: (robotId) => ({ robotId }),
    ACTIVATE: (robotId) => ({ robotId }),
    DEACTIVATE: (robotId) => ({ robotId }),
    SET_FOCUS: (robotId) => ({ robotId }),
    TOGGLE_OPTION: (robotId, key) => ({ robotId, key }),
    MODIFY_COLOR: (robotId, color) => ({ robotId, color }),
  },
  { prefix: 'ROBOT' }
);
