export const CAMERAS = [
  {
    name: 'Sony α7CR 24mm',
    specs: {
      focalLength: 24,
      sensorSize: { width: 35.7, height: 23.8 },
    },
  },
  {
    name: 'Sony ILX-LR1',
    specs: {
      focalLength: 24,
      sensorSize: { width: 35.7, height: 23.8 },
    },
  },
  {
    name: 'Sony RX0 II',
    specs: {
      focalLength: 24,
      sensorSize: { width: 13.2, height: 8.8 },
    },
  },
];
