import useMountEffect from '@restart/hooks/useMountEffect';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import classNames from 'classnames/bind';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import styles from './index.module.scss';

import actions from '@/actions';
import spinner from '@/assets/vectors/spinner.svg';
import { setAuthToken } from '@/helpers/API';
import { ToastService as toast } from '@/libs/Toast';

const cx = classNames.bind(styles);

const Page = () => {
  const dispatch = useDispatch();
  const licenses = useSelector((state) => state.licenses);
  const [modems, setModems] = useState([]);
  const [pathRobot, setPathRobot] = useState({});
  const navigate = useNavigate();

  useMountEffect(() => {
    // 라이선스
    window.electron.ipcRenderer.on('licenses', (data) => {
      dispatch(actions.license.load(data));
    });
    window.electron.ipcRenderer.send('loadLicenses');

    // USB 포트
    window.electron.ipcRenderer.on('ports', (ports) => {
      const nextModems = ports.filter((port) => port.path.startsWith('/dev/tty.usbmodem'));
      setModems(nextModems);
    });
    window.electron.ipcRenderer.send('startFindPorts');
  });

  useUpdateEffect(() => {
    const paths = modems.map(({ path }) => path);

    const nextPathRobot = {};
    paths.forEach((path) => {
      if (pathRobot[path]) {
        nextPathRobot[path] = pathRobot[path];
      }
    });
    setPathRobot(nextPathRobot);
  }, [modems]);

  const selectRobot = (e) => {
    const robotId = e.target.value;
    const { path } = e.target.closest('[data-path]').dataset;

    const nextPathRobot = { ...pathRobot };
    if (robotId) {
      nextPathRobot[path] = robotId;
    } else {
      delete nextPathRobot[path];
    }
    setPathRobot(nextPathRobot);
  };

  const doStart = () => {
    // 로봇 지정된 모뎀 없는 경우
    if (Object.keys(pathRobot).length === 0) {
      toast.error('Please select at least one.');
      return;
    }

    window.electron.ipcRenderer.send('stopFindPorts');

    setAuthToken('THIS_IS_AN_ACCESS_TOKEN_FOR_RUNNING_ELECTRON');
    navigate('/control-center', { replace: true, state: pathRobot });
  };

  return (
    <div className={cx('container')}>
      <form className={cx('form')}>
        <div className={cx('formInner')}>
          {modems.length === 0 && (
            <div className={cx('loader')}>
              <img src={spinner} alt="Loading" />
              <div className={cx('message')}>Waiting for connection.</div>
            </div>
          )}
          {modems.map((modem) => (
            <div key={modem.path} data-path={modem.path} className={cx('item')}>
              <select onChange={selectRobot}>
                <option value="">Select</option>
                {licenses.map((license, index) => {
                  const canSelect = !Object.values(pathRobot).includes(license.id);

                  return (
                    <option key={index} value={license.id} disabled={!canSelect}>
                      {license.name}
                    </option>
                  );
                })}
              </select>
              <table>
                <tbody>
                  {Object.entries(modem).map(([key, value], index) => (
                    <tr key={index}>
                      <th>{key}</th>
                      <td>{value ?? 'Unknown'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
          <button type="button" className={cx(['button', 'accent'])} onClick={doStart}>
            Start
          </button>
        </div>
      </form>
    </div>
  );
};

export default Page;
