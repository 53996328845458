import Dashboard from './Dashboard';
import MapMapper from './MapMapper';
import Organizer from './Organizer';

const map = {
  Dashboard,
  MapMapper,
  Organizer,
};

export default map;
