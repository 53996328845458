import classNames from 'classnames/bind';
import React from 'react';

import styles from './Waypoint.module.scss';

const cx = classNames.bind(styles);

const Waypoint = ({ color, data: waypoint }) => {
  return (
    <div className={cx('container')}>
      {waypoint.name && <div className={cx('name')}>{waypoint.name}</div>}
      <div className={cx('circle')} style={{ backgroundColor: color }}>
        <div className={cx('label')}>{waypoint.label}</div>
      </div>
      <div className={cx('arrow')} style={{ borderTopColor: color }} />
    </div>
  );
};

export default Waypoint;
