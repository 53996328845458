import useMountEffect from '@restart/hooks/useMountEffect';
import React, { useState } from 'react';

import { Mode } from '@/components/ui/Dashboard';
import EventEmitter from '@/libs/EventEmitter';

const MODE = {
  65536: 'Manual',
  458752: 'Stabilized',
  327680: 'Acro',
  131072: 'Altitude',
  196608: 'Position',
  33816576: 'Takeoff',
  50593792: 'Hold',
  67371008: 'Mission',
  84148224: 'Return',
  100925440: 'Land',
  134479872: 'Fllow Me',
  151257088: 'Precision Land',
};

const Component = ({ robotId }) => {
  const [mode, setMode] = useState();

  useMountEffect(() => {
    const subscribeToken = EventEmitter.subscribe(`${robotId}/telemetry/heartbeat`, (data) => {
      setMode(MODE[data.mode]);
    });

    return () => {
      EventEmitter.unsubscribe(subscribeToken);
    };
  });

  return <Mode robotId={robotId} mode={mode} />;
};

export default Component;
