import moment from 'moment';

export const toHoursAndMinutes = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60 / 60);
  const minutes = Math.floor(totalMinutes / 60);
  const seconds = totalMinutes % 60;

  return {
    hours,
    minutes,
    seconds,
  };
};

export const getDuration = (start, end) => {
  const diff = moment(end).diff(moment(start));
  return moment.utc(diff).format('HH:mm:ss');
};
