import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import classNames from 'classnames/bind';
import { toLonLat } from 'ol/proj';
import React, { useMemo } from 'react';
import { RiCrosshair2Line } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';

import styles from './index.module.scss';

import actions from '@/actions';
import OlMap from '@/helpers/OlMap';
import { getElevation } from '@/utils/MapUtils';

const cx = classNames.bind(styles);

const ClickPoint = () => {
  const dispatch = useDispatch();
  const mode = useSelector((state) => state.editor.mode);
  const map = OlMap.getMap();

  const isActive = useMemo(() => {
    return mode === 'point';
  }, [mode]);

  useUpdateEffect(() => {
    if (!isActive) return;

    map.on('click', handleClick);

    return () => {
      map.un('click', handleClick);
    };
  }, [isActive]);

  const handleClick = (e) => {
    const features = map.getFeaturesAtPixel(e.pixel);

    // 클릭 지점에 Feature 존재 시
    if (features.length > 0) return;

    const [lng, lat] = toLonLat(e.coordinate);
    addWaypoint({ lat, lng });
  };

  // 경로점 추가
  const addWaypoint = async (position) => {
    const elevation = await getElevation(position);
    const waypoint = { position, elevation };
    dispatch(actions.editor.appendWaypoint(waypoint));
  };

  const toggle = () => {
    if (isActive) {
      dispatch(actions.editor.changeMode(null));
    } else {
      dispatch(actions.editor.changeMode('point'));
    }
  };

  return (
    <div className={cx('container')}>
      <div className={cx(['button', { active: isActive }])} onClick={toggle}>
        <RiCrosshair2Line size={16} />
        <div className={cx('label')}>Point</div>
      </div>
    </div>
  );
};

export default ClickPoint;
