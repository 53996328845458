import produce from 'immer';
import { handleActions } from 'redux-actions';

const initialState = {
  robots: [],
  targetRobotIds: [],
  activeRobotIds: [],
  focusRobotId: null,
  options: {},
};

export default handleActions(
  {
    'ROBOT/RESET': (state, action) => initialState,
    'ROBOT/LOAD': (state, action) =>
      produce(state, (draft) => {
        const { robots } = action.payload;
        draft.robots = robots;
      }),
    'ROBOT/TOGGLE': (state, action) =>
      produce(state, (draft) => {
        const { robotId } = action.payload;

        // Focus 설정된 로봇 아닌 경우
        if (state.focusRobotId !== robotId) {
          draft.focusRobotId = null;
        }

        if (state.targetRobotIds.includes(robotId)) {
          draft.targetRobotIds.splice(state.targetRobotIds.indexOf(robotId), 1);
        } else {
          draft.targetRobotIds.push(robotId);
        }
      }),
    'ROBOT/ACTIVATE': (state, action) =>
      produce(state, (draft) => {
        const { robotId } = action.payload;

        draft.options[robotId] = {
          label: true,
          video: false,
        };

        const nextActiveRobotId = state.activeRobotIds.concat(robotId);
        draft.activeRobotIds = [...new Set(nextActiveRobotId)];
      }),
    'ROBOT/DEACTIVATE': (state, action) =>
      produce(state, (draft) => {
        const { robotId } = action.payload;

        // 로봇 선택 해제
        draft.targetRobotIds = state.targetRobotIds.filter((targetRobotId) => targetRobotId !== robotId);
        // 로봇 비활성화
        draft.activeRobotIds = state.activeRobotIds.filter((activeRobotId) => activeRobotId !== robotId);

        // Focus 설정된 로봇인 경우
        if (state.focusRobotId === robotId) {
          draft.focusRobotId = null;
        }

        delete draft.options[robotId];
      }),
    'ROBOT/SET_FOCUS': (state, action) =>
      produce(state, (draft) => {
        const { robotId } = action.payload;

        if (robotId) {
          draft.focusRobotId = robotId;
        } else {
          draft.focusRobotId = null;
        }
      }),
    'ROBOT/TOGGLE_OPTION': (state, action) =>
      produce(state, (draft) => {
        const { robotId, key } = action.payload;

        draft.options[robotId][key] = !state.options[robotId][key];
      }),
    'ROBOT/MODIFY_COLOR': (state, action) =>
      produce(state, (draft) => {
        const { robotId, color } = action.payload;

        const index = state.robots.findIndex((robot) => robot.id === robotId);
        draft.robots[index].color = color;
      }),
  },
  initialState
);
