import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import React, { useContext, useState, useRef } from 'react';
import { RiLoader2Line, RiCheckboxCircleFill } from 'react-icons/ri';

import styles from './console.module.scss';

import { MessageContext } from '@/helpers/MessageProvider';
import EventEmitter from '@/libs/EventEmitter';

const cx = classNames.bind(styles);

const Panel = ({ robot }) => {
  const { publishCommand } = useContext(MessageContext);
  const [isStarted, setStarted] = useState(false);
  const [isSuccess, setSuccess] = useState();
  const subscribeToken = useRef();

  useMountEffect(() => {
    return () => {
      unsubscribe();
    };
  });

  const unsubscribe = () => {
    if (subscribeToken.current) {
      EventEmitter.unsubscribe(subscribeToken.current);
      subscribeToken.current = null;
    }
  };

  const doStart = () => {
    setStarted(true);

    subscribeToken.current = EventEmitter.subscribe(`${robot.id}/telemetry/commandAck`, (data) => {
      // 241: MAV_CMD_PREFLIGHT_CALIBRATION
      if (data.command === 241) {
        unsubscribe();
        setSuccess(data.result === 0);
      }
    });
    publishCommand(robot, 'calibration/level_horizon', [[]]);
  };

  return (
    <div className={cx('container')}>
      <div className={cx('message')}>Place your vehicle in its level flight position.</div>
      {!isStarted && (
        <button type="button" className={cx('button')} onClick={doStart}>
          Start
        </button>
      )}
      {isStarted && isSuccess === undefined && (
        <div className={cx('status')}>
          <RiLoader2Line size={20} color="white" className={cx('spin')} />
          <div className={cx('text')}>Processing...</div>
        </div>
      )}
      {isSuccess && (
        <div className={cx('status')}>
          <RiCheckboxCircleFill size={20} color="green" className={cx('message')} />
          <div className={cx('text')}>Success!</div>
        </div>
      )}
      {isSuccess === false && (
        <div className={cx('status')}>
          <RiCheckboxCircleFill size={20} color="red" className={cx('message')} />
          <div className={cx('text')}>Failure!</div>
        </div>
      )}
    </div>
  );
};

export default Panel;
