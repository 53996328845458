import classNames from 'classnames/bind';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';

import styles from './common.module.scss';

import actions from '@/actions';
import { MessageContext } from '@/helpers/MessageProvider';

const cx = classNames.bind(styles);

const Action = ({ data: robots }) => {
  const dispatch = useDispatch();
  const { publishCommand } = useContext(MessageContext);

  const onAIMode = () => doCommand(0);
  const offAIMode = () => doCommand(1);

  const doCommand = (value) => {
    robots.forEach((robot) => {
      publishCommand(robot, 'sportmode/switch', { value });
    });

    dispatch(actions.command.toggle());
  };

  return (
    <div className={cx('container')}>
      <div className={cx('wrapper')}>
        <div className={cx('title')}>AI Mode</div>
        <div className={cx('message')}>Insert a description for this function.</div>
      </div>
      <div className={cx(['buttons', 'aiMode'])}>
        <div className={cx('button')} onClick={offAIMode}>
          OFF
        </div>
        <div className={cx('button')} onClick={onAIMode}>
          ON
        </div>
      </div>
    </div>
  );
};

export default Action;
