import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import React, { useState } from 'react';

import styles from './Body.module.scss';
import FootForceChart from './FootForceChart';
import GLTFViewer from './GLTFViewer';

import { Attitude } from '@/components/ui/Dashboard';
import EventEmitter from '@/libs/EventEmitter';

const cx = classNames.bind(styles);

const Body = ({ robotId }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [lowstate, setLowstate] = useState();

  useMountEffect(() => {
    const subscribeToken = EventEmitter.subscribe(`${robotId}/telemetry/lowstate`, setLowstate);

    return () => {
      EventEmitter.unsubscribe(subscribeToken);
    };
  });

  const selectTab = (e) => {
    e.stopPropagation();
    setActiveTabIndex(Number(e.target.dataset.index));
  };

  return (
    <div className={cx('container')}>
      <GLTFViewer data={robotId} />
      <Attitude data={robotId} />
      <div className={cx('values')}>
        <div className={cx('tabs')}>
          <div data-index={0} className={cx(['tab', { active: activeTabIndex === 0 }])} onClick={selectTab}>
            Low-level
          </div>
          <div data-index={1} className={cx(['tab', { active: activeTabIndex === 1 }])} onClick={selectTab}>
            IMU State
          </div>
          <div data-index={2} className={cx(['tab', { active: activeTabIndex === 2 }])} onClick={selectTab}>
            Motor State
          </div>
        </div>
        <div className={cx('panes')}>
          {/* Low */}
          <div className={cx(['pane', { active: activeTabIndex === 0 }])}>
            <div className={cx('item')}>
              <div className={cx('header')}>
                <div className={cx('title')}>Foot Force</div>
              </div>
              <table cellSpacing={0}>
                <thead>
                  <tr>
                    <td width="65">Front Left</td>
                    <td width="65">Front Right</td>
                    <td width="65">Rear Left</td>
                    <td width="65">Rear Right</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{lowstate?.foot_force['0'].toFixed(1) ?? 0}</td>
                    <td>{lowstate?.foot_force['1'].toFixed(1) ?? 0}</td>
                    <td>{lowstate?.foot_force['2'].toFixed(1) ?? 0}</td>
                    <td>{lowstate?.foot_force['3'].toFixed(1) ?? 0}</td>
                  </tr>
                </tbody>
              </table>
              <div className={cx('chartWrapper')}>
                <FootForceChart data={lowstate?.foot_force} />
              </div>
            </div>
            <div className={cx('item')}>
              <div className={cx('header')}>
                <div className={cx('title')}>Foot Force (Est.)</div>
              </div>
              <table cellSpacing={0}>
                <thead>
                  <tr>
                    <td width="65">Front Left</td>
                    <td width="65">Front Right</td>
                    <td width="65">Rear Left</td>
                    <td width="65">Rear Right</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{lowstate?.foot_force_est['0'].toFixed(1) ?? 0}</td>
                    <td>{lowstate?.foot_force_est['1'].toFixed(1) ?? 0}</td>
                    <td>{lowstate?.foot_force_est['2'].toFixed(1) ?? 0}</td>
                    <td>{lowstate?.foot_force_est['3'].toFixed(1) ?? 0}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {/* IMU */}
          <div className={cx(['pane', { active: activeTabIndex === 1 }])}>
            <div className={cx('item')}>
              <div className={cx('header')}>
                <div className={cx('title')}>Quaternion</div>
              </div>
              <table cellSpacing={0}>
                <thead>
                  <tr>
                    <td width="65">W</td>
                    <td width="65">X</td>
                    <td width="65">Y</td>
                    <td width="65">Z</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{lowstate?.imu_state.quaternion['0'].toFixed(3) ?? 0}</td>
                    <td>{lowstate?.imu_state.quaternion['1'].toFixed(3) ?? 0}</td>
                    <td>{lowstate?.imu_state.quaternion['2'].toFixed(3) ?? 0}</td>
                    <td>{lowstate?.imu_state.quaternion['3'].toFixed(3) ?? 0}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className={cx('item')}>
              <div className={cx('header')}>
                <div className={cx('title')}>Gyroscope</div>
                <div className={cx('unit')}>(rad/s)</div>
              </div>
              <table cellSpacing={0}>
                <thead>
                  <tr>
                    <td width={260 / 3}>X</td>
                    <td width={260 / 3}>Y</td>
                    <td width={260 / 3}>Z</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{lowstate?.imu_state.gyroscope['0'].toFixed(3) ?? 0}</td>
                    <td>{lowstate?.imu_state.gyroscope['1'].toFixed(3) ?? 0}</td>
                    <td>{lowstate?.imu_state.gyroscope['2'].toFixed(3) ?? 0}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className={cx('item')}>
              <div className={cx('header')}>
                <div className={cx('title')}>Accelerometer</div>
                <div className={cx('unit')}>(m/s²)</div>
              </div>
              <table cellSpacing={0}>
                <thead>
                  <tr>
                    <td width={260 / 3}>X</td>
                    <td width={260 / 3}>Y</td>
                    <td width={260 / 3}>Z</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{lowstate?.imu_state.accelerometer['0'].toFixed(3) ?? 0}</td>
                    <td>{lowstate?.imu_state.accelerometer['1'].toFixed(3) ?? 0}</td>
                    <td>{lowstate?.imu_state.accelerometer['2'].toFixed(3) ?? 0}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className={cx('item')}>
              <div className={cx('header')}>
                <div className={cx('title')}>Attitude</div>
                <div className={cx('unit')}>(rad)</div>
              </div>
              <table cellSpacing={0}>
                <thead>
                  <tr>
                    <td width={260 / 3}>Roll</td>
                    <td width={260 / 3}>Pitch</td>
                    <td width={260 / 3}>Yaw</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{lowstate?.imu_state.rpy['0'].toFixed(3) ?? 0}</td>
                    <td>{lowstate?.imu_state.rpy['1'].toFixed(3) ?? 0}</td>
                    <td>{lowstate?.imu_state.rpy['2'].toFixed(3) ?? 0}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className={cx(['pane', { active: activeTabIndex === 2 }])}>
            <div className={cx('item')}>
              <table cellSpacing={0}>
                <thead>
                  <tr>
                    <td>Pos.</td>
                    <td width="40">Part</td>
                    <td width="40">Angle</td>
                    <td width="40">Vel.</td>
                    <td width="40">Acc.</td>
                    <td width="40">Torque</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td rowSpan={3}>Front Left</td>
                    <td>Hip</td>
                    <td>{lowstate?.motor_state[0].q.toFixed(3) ?? 0}</td>
                    <td>{lowstate?.motor_state[0].dq.toFixed(3) ?? 0}</td>
                    <td>{lowstate?.motor_state[0].ddq.toFixed(3) ?? 0}</td>
                    <td>{lowstate?.motor_state[0].tau_est.toFixed(3) ?? 0}</td>
                  </tr>
                  <tr>
                    <td>Thigh</td>
                    <td>{lowstate?.motor_state[1].q.toFixed(3) ?? 0}</td>
                    <td>{lowstate?.motor_state[1].dq.toFixed(3) ?? 0}</td>
                    <td>{lowstate?.motor_state[1].ddq.toFixed(3) ?? 0}</td>
                    <td>{lowstate?.motor_state[1].tau_est.toFixed(3) ?? 0}</td>
                  </tr>
                  <tr>
                    <td>Calf</td>
                    <td>{lowstate?.motor_state[2].q.toFixed(3) ?? 0}</td>
                    <td>{lowstate?.motor_state[2].dq.toFixed(3) ?? 0}</td>
                    <td>{lowstate?.motor_state[2].ddq.toFixed(3) ?? 0}</td>
                    <td>{lowstate?.motor_state[2].tau_est.toFixed(3) ?? 0}</td>
                  </tr>
                  <tr>
                    <td rowSpan={3}>Front Right</td>
                    <td>Hip</td>
                    <td>{lowstate?.motor_state[3].q.toFixed(3) ?? 0}</td>
                    <td>{lowstate?.motor_state[3].dq.toFixed(3) ?? 0}</td>
                    <td>{lowstate?.motor_state[3].ddq.toFixed(3) ?? 0}</td>
                    <td>{lowstate?.motor_state[3].tau_est.toFixed(3) ?? 0}</td>
                  </tr>
                  <tr>
                    <td>Thigh</td>
                    <td>{lowstate?.motor_state[4].q.toFixed(3) ?? 0}</td>
                    <td>{lowstate?.motor_state[4].dq.toFixed(3) ?? 0}</td>
                    <td>{lowstate?.motor_state[4].ddq.toFixed(3) ?? 0}</td>
                    <td>{lowstate?.motor_state[4].tau_est.toFixed(3) ?? 0}</td>
                  </tr>
                  <tr>
                    <td>Calf</td>
                    <td>{lowstate?.motor_state[5].q.toFixed(3) ?? 0}</td>
                    <td>{lowstate?.motor_state[5].dq.toFixed(3) ?? 0}</td>
                    <td>{lowstate?.motor_state[5].ddq.toFixed(3) ?? 0}</td>
                    <td>{lowstate?.motor_state[5].tau_est.toFixed(3) ?? 0}</td>
                  </tr>
                  <tr>
                    <td rowSpan={3}>Rear Left</td>
                    <td>Hip</td>
                    <td>{lowstate?.motor_state[6].q.toFixed(3) ?? 0}</td>
                    <td>{lowstate?.motor_state[6].dq.toFixed(3) ?? 0}</td>
                    <td>{lowstate?.motor_state[6].ddq.toFixed(3) ?? 0}</td>
                    <td>{lowstate?.motor_state[6].tau_est.toFixed(3) ?? 0}</td>
                  </tr>
                  <tr>
                    <td>Thigh</td>
                    <td>{lowstate?.motor_state[7].q.toFixed(3) ?? 0}</td>
                    <td>{lowstate?.motor_state[7].dq.toFixed(3) ?? 0}</td>
                    <td>{lowstate?.motor_state[7].ddq.toFixed(3) ?? 0}</td>
                    <td>{lowstate?.motor_state[7].tau_est.toFixed(3) ?? 0}</td>
                  </tr>
                  <tr>
                    <td>Calf</td>
                    <td>{lowstate?.motor_state[8].q.toFixed(3) ?? 0}</td>
                    <td>{lowstate?.motor_state[8].dq.toFixed(3) ?? 0}</td>
                    <td>{lowstate?.motor_state[8].ddq.toFixed(3) ?? 0}</td>
                    <td>{lowstate?.motor_state[8].tau_est.toFixed(3) ?? 0}</td>
                  </tr>
                  <tr>
                    <td rowSpan={3}>Rear Right</td>
                    <td>Hip</td>
                    <td>{lowstate?.motor_state[9].q.toFixed(3) ?? 0}</td>
                    <td>{lowstate?.motor_state[9].dq.toFixed(3) ?? 0}</td>
                    <td>{lowstate?.motor_state[9].ddq.toFixed(3) ?? 0}</td>
                    <td>{lowstate?.motor_state[9].tau_est.toFixed(3) ?? 0}</td>
                  </tr>
                  <tr>
                    <td>Thigh</td>
                    <td>{lowstate?.motor_state[10].q.toFixed(3) ?? 0}</td>
                    <td>{lowstate?.motor_state[10].dq.toFixed(3) ?? 0}</td>
                    <td>{lowstate?.motor_state[10].ddq.toFixed(3) ?? 0}</td>
                    <td>{lowstate?.motor_state[10].tau_est.toFixed(3) ?? 0}</td>
                  </tr>
                  <tr>
                    <td>Calf</td>
                    <td>{lowstate?.motor_state[11].q.toFixed(3) ?? 0}</td>
                    <td>{lowstate?.motor_state[11].dq.toFixed(3) ?? 0}</td>
                    <td>{lowstate?.motor_state[11].ddq.toFixed(3) ?? 0}</td>
                    <td>{lowstate?.motor_state[11].tau_est.toFixed(3) ?? 0}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
