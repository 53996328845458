import { MavLinkData, MavLinkPacketField } from 'node-mavlink';

class UsvOilactGcsData extends MavLinkData {
  constructor() {
    super();
    this.targetSysid = 0;
    this.oilcollect_act = 0;
    this.oilsuction_act = 0;
  }
}

UsvOilactGcsData.MSG_ID = 1104;
UsvOilactGcsData.MSG_NAME = 'UsvOilactGcsData';
UsvOilactGcsData.PAYLOAD_LENGTH = 9;
UsvOilactGcsData.MAGIC_NUMBER = 20;
UsvOilactGcsData.FIELDS = [
  new MavLinkPacketField('targetSysid', 'targetSysid', 0, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('oilcollect_act', 'oilcollect_act', 1, false, 4, 'int32_t', ''),
  new MavLinkPacketField('oilsuction_act', 'oilsuction_act', 5, false, 4, 'int32_t', ''),
];

export default UsvOilactGcsData;
