import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import React from 'react';

import Robot from './Robot';

import OlMap from '@/helpers/OlMap';

const MapMapper = ({ data: robot }) => {
  useUpdateEffect(() => {
    OlMap.changeColor(robot.id, robot.color);
  }, [robot.color]);

  return <Robot data={robot} />;
};

export default MapMapper;
