import classNames from 'classnames/bind';
import React from 'react';

import styles from './MissionItemRow.module.scss';

const cx = classNames.bind(styles);

const MissionItemRow = ({ label, unit, children }) => {
  return (
    <div className={cx('container')}>
      <label>
        {label}
        <div className={cx('wrapper')}>
          {children}
          <span className={cx('unit')}>{unit}</span>
        </div>
      </label>
    </div>
  );
};

export default MissionItemRow;
