import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import React, { useRef } from 'react';
import { useDispatch, useStore } from 'react-redux';

import DrawingToolbar from './DrawingToolbar';
import styles from './index.module.scss';
import Labels from './Labels';
import MapToolbar from './MapToolbar';
import Path from './Path';
import Shoots from './Shoots';
import Survey from './Survey';
import Waypoints from './Waypoints';

import actions from '@/actions';
import Cross from '@/components/ui/map/Cross';
import Selectors from '@/components/ui/map/Selectors';
import OlMap from '@/helpers/OlMap';

const cx = classNames.bind(styles);

const Map = () => {
  const store = useStore();
  const dispatch = useDispatch();
  const map = useRef();
  const mapRef = useRef();

  useMountEffect(() => {
    // redux-persist 활용한 최종 center 및 zoom 적용
    const { center, zoom } = store.getState().map;
    map.current = OlMap.init(mapRef.current, { center, zoom });

    // 지도 이동 중일 시
    map.current.getView().on('change:center', () => {
      const { lat, lng } = OlMap.getCenter();
      dispatch(actions.map.setCenter(lat, lng));
    });

    // 지도 이동 또는 줌 완료 시
    map.current.on('moveend', () => {
      const { lat, lng } = OlMap.getCenter();
      const zoom = map.current.getView().getZoom();

      dispatch(actions.map.setPositionAndZoom(lat, lng, zoom));
    });
  });

  return (
    <div className={cx('container')}>
      <div ref={mapRef} className={cx('map')} />
      <Path />
      <Labels />
      <Shoots />
      <Waypoints />
      <Survey />
      <DrawingToolbar />
      <Selectors />
      <Cross />
      <MapToolbar />
    </div>
  );
};

export default Map;
