import useMountEffect from '@restart/hooks/useMountEffect';
import { useContext } from 'react';
import { useDispatch, useStore } from 'react-redux';

import actions from '@/actions';
import { MessageContext } from '@/helpers/MessageProvider';
import EventEmitter from '@/libs/EventEmitter';

const Action = ({ data: robots }) => {
  const dispatch = useDispatch();
  const store = useStore();
  const { publishCommand } = useContext(MessageContext);

  useMountEffect(() => {
    robots.forEach((robot) => {
      const { lat, lon: lng, alt } = store.getState().telemetry[robot.id].m33;
      publishCommand(robot, 'action/brake/px4/copter', [[NaN, lat, lng, alt / 1000]]);

      EventEmitter.publish(`${robot.id}/event/removeGoto`);
    });

    dispatch(actions.command.toggle());
  });

  return null;
};

export default Action;
