import classNames from 'classnames/bind';
import commaNumber from 'comma-number';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import Chart from './Chart';
import styles from './index.module.scss';
import Item from './Item';

import Bar from '@/components/ui/Bar';
import { getDistanceAlongPath } from '@/utils/MapUtils';

const cx = classNames.bind(styles);

const Mission = () => {
  const targetRobotIds = useSelector((state) => state.robot.targetRobotIds);
  const missionItems = useSelector((state) => state.mission[targetRobotIds[0]]);

  // 총 거리
  const totalDistance = useMemo(() => {
    if (!missionItems) return 0;

    const path = [];
    missionItems.forEach((missionItem) => {
      switch (missionItem.type) {
        case 'navLand':
        case 'navLoiterToAlt':
        case 'navTakeoff':
        case 'navWaypoint':
          path.push(missionItem.data.position);
          break;

        case 'navReturnToLaunch':
          path.push(missionItems[0].data.position);
          break;

        default:
          break;
      }
    });

    return getDistanceAlongPath(path);
  }, [missionItems]);

  // 미션 없는 경우, 또는 선택 로봇 1개 초과 경우
  if (!missionItems || targetRobotIds.length > 1) return;

  return (
    <div className={cx('container')}>
      <div className={cx('header')}>
        <div className={cx('title')}>Mission</div>
        <div className={cx('info')}>
          <div className={cx('item')}>
            {commaNumber(missionItems.length ?? 0)} <span>items</span>
          </div>
          <Bar />
          <div className={cx('item')}>
            {commaNumber((totalDistance / 1000).toFixed(1))}
            <span>km</span>
          </div>
        </div>
      </div>
      <div className={cx('list')}>
        <div className={cx('wrapper')}>
          {missionItems.map((missionItem, index) => (
            <Item key={missionItem.id} label={index + 1} data={missionItem} />
          ))}
        </div>
      </div>
      <Chart data={missionItems} />
    </div>
  );
};

export default Mission;
