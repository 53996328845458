export const outboundLog = (to, log) => {
  console.debug(`%c[WEB → ${to}]`, `color: purple`, log);
};

export const inboundLog = (from, log, success) => {
  const color = success ? 'green' : 'red';

  if (success) {
    console.debug(`%c[${from} → WEB]`, `color: ${color}`, log);
  } else {
    console.error(`%c[${from} → WEB]`, `color: ${color}`, log);
  }
};
