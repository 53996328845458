import classNames from 'classnames/bind';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './NotFound.module.scss';

const cx = classNames.bind(styles);

const NotFound = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate('/');
  };

  return (
    <div className={cx('container')}>
      <div className={cx('form')}>
        <div className={cx('message')}>
          That page doesn't exist.
          <br />
          <span className={cx('accent')}>Please check the address again.</span>
          <br />
          If the problem persists, please contact the administrator.
        </div>
        <button type="button" className={cx(['button', 'accent'])} onClick={goBack}>
          Go Back
        </button>
      </div>
    </div>
  );
};

export default NotFound;
