import useMountEffect from '@restart/hooks/useMountEffect';
import React, { useState } from 'react';

import { Mode } from '@/components/ui/Dashboard';
import EventEmitter from '@/libs/EventEmitter';

const Component = ({ robotId }) => {
  const [mode, setMode] = useState();

  useMountEffect(() => {
    const subscribeToken = EventEmitter.subscribe(`${robotId}/event/ping`, (data) => {
      setMode(data.mode);
    });

    return () => {
      EventEmitter.unsubscribe(subscribeToken);
    };
  });

  return <Mode robotId={robotId} mode={mode} />;
};

export default Component;
