import { createActions } from 'redux-actions';

export default createActions(
  {
    RESET: () => ({}),
    LOAD: (robotId, missionItems) => ({ robotId, missionItems }),
    UNLOAD: (robotId) => ({ robotId }),
  },
  { prefix: 'MISSION' }
);
