import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import React, { useState } from 'react';

import styles from './Body.module.scss';

import EventEmitter from '@/libs/EventEmitter';

const cx = classNames.bind(styles);

const Body = ({ robotId }) => {
  const [data, setData] = useState();

  useMountEffect(() => {
    const subscribeToken = EventEmitter.subscribe(`${robotId}/event/ping`, setData);

    return () => {
      EventEmitter.unsubscribe(subscribeToken);
    };
  });

  return (
    <div className={cx('container')}>
      <div className={cx('boxes')}>
        <div className={cx('box')}>
          <div className={cx('label')}>Temperature</div>
          <div className={cx('value')}>
            {data ? data.temperature.toFixed(1) : 0}
            <span className={cx('unit')}>°C</span>
          </div>
        </div>
        <div className={cx('box')}>
          <div className={cx('label')}>Humidity</div>
          <div className={cx('value')}>
            {data ? data.humidity.toFixed(1) : 0}
            <span className={cx('unit')}>%</span>
          </div>
        </div>
        <div className={cx('box')}>
          <div className={cx('label')}>Wind</div>
          <div className={cx('direction')} style={{ transform: `rotate(${data ? data.wind_direction : 0}deg)` }}>
            <div className={cx('left')} />
            <div className={cx('right')} />
          </div>
          <div className={cx('value')}>
            {data ? data.wind_speed.toFixed(1) : 0}
            <span className={cx('unit')}>m/s</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
