import classNames from 'classnames/bind';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';

import styles from './common.module.scss';

import actions from '@/actions';
import ConfirmSlider from '@/components/ui/ConfirmSlider';
import { MessageContext } from '@/helpers/MessageProvider';
import EventEmitter from '@/libs/EventEmitter';

const cx = classNames.bind(styles);

const Action = ({ data: robots }) => {
  const dispatch = useDispatch();
  const { publishCommand } = useContext(MessageContext);

  const doCommand = () => {
    robots.forEach((robot) => {
      publishCommand(robot, 'action/qrtl', [[]]);
      EventEmitter.publish(`${robot.id}/event/removeGoto`);
    });

    dispatch(actions.command.toggle());
  };

  return (
    <div className={cx('container')}>
      <div className={cx('message')}>Return to launch point</div>
      <ConfirmSlider onConfirm={doCommand} />
    </div>
  );
};

export default Action;
