import classNames from 'classnames/bind';
import { fromLonLat } from 'ol/proj';
import React, { useEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import { RiNotificationBadgeLine } from 'react-icons/ri';
import { Provider, useDispatch, useStore } from 'react-redux';

import styles from './useIoTHubEvent.module.scss';

import actions from '@/actions';
import ImageViewerModal from '@/components/modals/ImageViewer';
import OlMap from '@/helpers/OlMap';
import EventEmitter from '@/libs/EventEmitter';
import { ModalService as modal } from '@/libs/Modal';

const cx = classNames.bind(styles);

const MarkerContent = ({ event, color }) => {
  const clickEvent = () => {
    switch (event.action) {
      case 'showImage':
        modal.show(ImageViewerModal, event.data);
        break;

      default:
        break;
    }
  };

  return (
    <div className={cx('container')} style={{ backgroundColor: color }} onClick={clickEvent}>
      <RiNotificationBadgeLine size={12} color="white" />
    </div>
  );
};

const useIoTHubEvent = (robot) => {
  const dispatch = useDispatch();
  const overlay = useRef();
  const store = useStore();
  const map = OlMap.getMap();

  useEffect(() => {
    const subscribeToken = EventEmitter.subscribe(`${robot.id}/event/iothub`, ({ lat, lng, event }) => {
      const element = getMarkerElement(event, robot.color);
      element.addEventListener('mouseenter', () => {
        dispatch(actions.callout.setText(`${event.data.caption} (${event.data.timestamp})`));
      });
      element.addEventListener('mouseleave', () => {
        dispatch(actions.callout.setText(null));
      });

      overlay.current = OlMap.createOverlay({
        element,
        position: fromLonLat([lng, lat]),
        positioning: 'center-center',
      });
      map.addOverlay(overlay.current);
    });

    return () => {
      EventEmitter.unsubscribe(subscribeToken);
      map.removeOverlay(overlay.current);
    };
  }, [robot]);

  const getMarkerElement = (event, color) => {
    const content = document.createElement('div');

    createRoot(content).render(
      <Provider store={store}>
        <MarkerContent event={event} color={color} />
      </Provider>
    );
    return content;
  };
};

export default useIoTHubEvent;
