import useMountEffect from '@restart/hooks/useMountEffect';

import OlMap from '@/helpers/OlMap';
import EventEmitter from '@/libs/EventEmitter';

const useCameraFeedback = (robotId) => {
  useMountEffect(() => {
    const subscribeToken = EventEmitter.subscribe(`${robotId}/telemetry/cameraFeedback`, ({ lat, lng }) => {
      OlMap.addShoot(robotId, { lat, lng });
    });

    return () => {
      EventEmitter.unsubscribe(subscribeToken);
      OlMap.removeShoot(robotId);
    };
  });
};

export default useCameraFeedback;
