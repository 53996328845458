import { createActions } from 'redux-actions';

export default createActions(
  {
    RESET: () => ({}),
    LOAD: (data) => ({ data }),
    REMOVE: (robotId) => ({ robotId }),
  },
  { prefix: 'EVENT' }
);
