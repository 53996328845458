import classNames from 'classnames/bind';
import React from 'react';
import { RiCheckLine } from 'react-icons/ri';

import styles from './Checkbox.module.scss';

const cx = classNames.bind(styles);

const Checkbox = ({ checked, size = 14, onClick }) => {
  return (
    <div className={cx('container')} style={{ width: size, height: size }} onClick={onClick}>
      {checked && <RiCheckLine size={size} color="white" />}
    </div>
  );
};

export default Checkbox;
