import produce from 'immer';
import { handleActions } from 'redux-actions';

const initialState = {};

export default handleActions(
  {
    'TELEMETRY/RESET': (state, action) => initialState,
    'TELEMETRY/LOAD_MAVLINK': (state, action) =>
      produce(state, (draft) => {
        const { data } = action.payload;

        if (!state[data.robotId]) {
          draft[data.robotId] = {};
        }

        // PARAM_VALUE
        // https://mavlink.io/en/messages/common.html#PARAM_VALUE
        if (data.msgId === 22) {
          if (!state[data.robotId] || !state[data.robotId][`m${data.msgId}`]) {
            draft[data.robotId][`m${data.msgId}`] = {};
          } else {
            draft[data.robotId][`m${data.msgId}`][data.payload.paramIndex] = data.payload;
          }
        } else {
          draft[data.robotId][`m${data.msgId}`] = data.payload;
        }
      }),
    'TELEMETRY/LOAD_ROS': (state, action) =>
      produce(state, (draft) => {
        const { data } = action.payload;

        if (!state[data.robotId]) {
          draft[data.robotId] = {};
        }

        const splitted = data.topic.split('/');
        const key = splitted[splitted.length - 1];
        draft[data.robotId][key] = data.payload;
      }),
    'TELEMETRY/LOAD_IOT': (state, action) =>
      produce(state, (draft) => {
        const { data } = action.payload;

        if (!state[data.robotId]) {
          draft[data.robotId] = {};
        }

        draft[data.robotId] = data.payload;
      }),
    'TELEMETRY/REMOVE': (state, action) =>
      produce(state, (draft) => {
        const { robotId } = action.payload;
        delete draft[robotId];
      }),
  },
  initialState
);
