import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import React, { useState } from 'react';
import { RiCheckLine, RiErrorWarningLine } from 'react-icons/ri';

import styles from './Toast.module.scss';

const cx = classNames.bind(styles);

const Toast = ({ data }) => {
  const [show, setShow] = useState(false);

  useMountEffect(() => {
    setShow(true);
  });

  return (
    <div className={cx(['container', { show }, data.type])}>
      {data.type === 'success' && <RiCheckLine size={20} color="white" />}
      {data.type === 'error' && <RiErrorWarningLine size={20} color="white" />}
      <div className={cx('content')}>{data.content}</div>
    </div>
  );
};

export default Toast;
