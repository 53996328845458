import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import * as turf from '@turf/turf';
import classNames from 'classnames/bind';
import { Draw } from 'ol/interaction';
import { toLonLat } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import { Style, Stroke, Fill } from 'ol/style';
import React, { useRef, useMemo } from 'react';
import { RiShapeLine } from 'react-icons/ri';
import { useDispatch, useSelector, useStore } from 'react-redux';

import styles from './index.module.scss';

import actions from '@/actions';
import { SURVEY_DEFAULT_OPTIONS, SURVEY_AREA_MAX } from '@/config';
import OlMap from '@/helpers/OlMap';
import { ToastService as toast } from '@/libs/Toast';
import CoordUtils from '@/utils/CoordUtils';
import { getPositions } from '@/utils/SurveyCalculator';

const cx = classNames.bind(styles);

const style = new Style({
  stroke: new Stroke({ color: 'black', width: 4 }),
  fill: new Fill({ color: [0, 0, 0, 0.3] }),
});

const DrawPolygon = () => {
  const dispatch = useDispatch();
  const mode = useSelector((state) => state.editor.mode);
  const store = useStore();
  const interaction = useRef();
  const map = OlMap.getMap();

  const isActive = useMemo(() => {
    return mode === 'polygon';
  }, [mode]);

  useUpdateEffect(() => {
    if (!isActive) return;

    const source = new VectorSource({ wrapX: false });

    interaction.current = new Draw({
      source,
      type: 'Polygon',
      freehand: false,
      style,
    });
    interaction.current.on('drawend', drawPolygon);
    map.addInteraction(interaction.current);

    return () => {
      map.removeInteraction(interaction.current);
      interaction.current = null;
    };
  }, [isActive]);

  const drawPolygon = (e) => {
    const coords = e.feature.getGeometry().getCoordinates()[0];
    const polygon = turf.polygon([coords.map((coord) => toLonLat(coord))]);

    const selfIntersections = turf.kinks(polygon).features;
    // 자기교차점 존재 시
    if (selfIntersections.length > 0) {
      toast.error('The drawn polygon cannot have self-intersections. Please redraw.');
      return;
    }

    const area = turf.area(polygon);
    // 면적 200km² 초과 시
    if (area > SURVEY_AREA_MAX) {
      toast.error('The drawn polygon cannot exceed 200 square kilometers. Please redraw.');
      return;
    }

    const boundary = coords.map((coord) => {
      return CoordUtils.objectFromArray(toLonLat(coord));
    });
    const positions = getPositions(boundary, SURVEY_DEFAULT_OPTIONS);
    // 경로점 미존재 시
    if (positions.length === 0) {
      toast.error('The polygon is too small. Please redraw.');
      return;
    }

    dispatch(actions.editor.appendSurvey(boundary, positions));
    dispatch(actions.editor.changeMode(null));
  };

  const toggle = () => {
    if (store.getState().editor.missionItems.length === 0) {
      toast.error('Please add a takeoff point first.');
      return;
    }

    if (isActive) {
      dispatch(actions.editor.changeMode(null));
    } else {
      dispatch(actions.editor.changeMode('polygon'));
    }
  };

  return (
    <div className={cx('container')}>
      <div className={cx(['button', { active: isActive }])} onClick={toggle}>
        <RiShapeLine size={16} />
        <div className={cx('label')}>Polygon</div>
      </div>
    </div>
  );
};

export default DrawPolygon;
