import classNames from 'classnames/bind';
import React, { useRef, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import styles from './common.module.scss';

import actions from '@/actions';
import Checkbox from '@/components/ui/Checkbox';
import MissionItemRow from '@/components/ui/MissionItemRow';
import { getElevation } from '@/utils/MapUtils';

const cx = classNames.bind(styles);

const MissionItem = ({ index, data: missionItem }) => {
  const dispatch = useDispatch();
  const domRefs = useRef({
    latitude: useRef(),
    longitude: useRef(),
    elevation: useRef(),
  }).current;

  const hasParachute = useMemo(() => {
    return missionItem.data.parachute === true;
  }, [missionItem.data]);

  useEffect(() => {
    domRefs.latitude.current.value = missionItem.data.position.lat.toFixed(7);
    domRefs.longitude.current.value = missionItem.data.position.lng.toFixed(7);
    domRefs.elevation.current.value = missionItem.data.elevation;
  }, [missionItem.data]);

  const updatePosition = (position) => {
    getElevation(position).then((elevation) => {
      const waypoint = { position, elevation };
      dispatch(actions.editor.moveWaypoint(missionItem.id, waypoint));
    });
  };

  const handleBlur = (e) => {
    const name = e.target.name;
    const value = Number(e.target.value);

    // Latitude 변경된 경우
    if (name === 'latitude') {
      updatePosition({ lat: value, lng: missionItem.data.position.lng });
    }
    // Longitude 변경된 경우
    else if (name === 'longitude') {
      updatePosition({ lat: missionItem.data.position.lat, lng: value });
    }
  };

  const toggleParachute = () => {
    dispatch(actions.editor.editWaypoint(index, 'parachute', !hasParachute));
  };

  return (
    <div className={cx('container')}>
      <MissionItemRow label="Latitude">
        <input
          ref={domRefs.latitude}
          name="latitude"
          type="number"
          onBlur={handleBlur}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.target.blur();
            }
          }}
        />
      </MissionItemRow>
      <MissionItemRow label="Longitude">
        <input
          ref={domRefs.longitude}
          name="longitude"
          type="number"
          onBlur={handleBlur}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.target.blur();
            }
          }}
        />
      </MissionItemRow>
      <MissionItemRow label="Elevation" unit="m">
        <input ref={domRefs.elevation} name="elevation" type="number" readOnly />
      </MissionItemRow>
      <MissionItemRow label="Parachute">
        <Checkbox checked={hasParachute} onClick={toggleParachute} />
      </MissionItemRow>
    </div>
  );
};

export default MissionItem;
