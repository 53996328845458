import classNames from 'classnames/bind';
import React, { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import styles from './common.module.scss';

import actions from '@/actions';
import MissionItemRow from '@/components/ui/MissionItemRow';

const cx = classNames.bind(styles);

const MissionItem = ({ index, data: missionItem }) => {
  const dispatch = useDispatch();
  const domRefs = useRef({
    gripper: useRef(),
    action: useRef(),
  }).current;

  useEffect(() => {
    domRefs.gripper.current.value = missionItem.data.gripper;
    domRefs.action.current.value = missionItem.data.action;
  }, [missionItem.data]);

  const handleBlur = (e) => {
    const name = e.target.name;
    const value = Number(e.target.value);
    dispatch(actions.editor.editGripper(index, name, value));
  };

  return (
    <div className={cx('container')}>
      <MissionItemRow label="Gripper Instance Number">
        <input
          ref={domRefs.gripper}
          name="gripper"
          type="number"
          onBlur={handleBlur}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.target.blur();
            }
          }}
        />
      </MissionItemRow>
      <MissionItemRow label="Action">
        <select ref={domRefs.action} name="action" onChange={handleBlur}>
          <option value="0">Release</option>
          <option value="1">Grab</option>
        </select>
      </MissionItemRow>
    </div>
  );
};

export default MissionItem;
