import classNames from 'classnames/bind';
import React, { useRef } from 'react';
import { MdOutlineOpenInNew } from 'react-icons/md';
import { v4 as uuidv4 } from 'uuid';

import styles from './ViewFinder.module.scss';

import { VIDEO_DEFAULT_WIDTH, VIDEO_DEFAULT_HEIGHT } from '@/config';

const cx = classNames.bind(styles);

const ViewFinder = ({ url }) => {
  const viewFinderRef = useRef();

  const openWindow = (e) => {
    e.stopPropagation();
    window.open(url);
  };

  return (
    <div className={cx('container')}>
      <iframe
        ref={viewFinderRef}
        title={uuidv4()}
        src={url}
        width={VIDEO_DEFAULT_WIDTH}
        height={VIDEO_DEFAULT_HEIGHT}
        scrolling="no"
        className={cx('viewFinder')}
      />
      <div className={cx('overlay')}>
        <div onClick={openWindow} className={cx('button')}>
          <MdOutlineOpenInNew size={16} color="white" title="New Window" />
        </div>
      </div>
    </div>
  );
};

export default ViewFinder;
