import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import React, { useState } from 'react';

import styles from './Modal.module.scss';

import { ModalService as modal } from '@/libs/Modal';

const cx = classNames.bind(styles);

const Modal = ({ disableEscape = false, children }) => {
  const [show, setShow] = useState(false);

  useMountEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 100);

    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  });

  const onKeyDown = (e) => {
    if (!disableEscape && e.code === 'Escape') {
      close();
    }
  };

  const close = () => {
    if (disableEscape) return;

    modal.hide();
  };

  return (
    <div className={cx('container')}>
      <div className={cx('dim')} onClick={close} />
      <div className={cx(['modal', { show }])}>{children}</div>
    </div>
  );
};

export default Modal;
