import useMountEffect from '@restart/hooks/useMountEffect';
import React, { useState } from 'react';

import { Mode } from '@/components/ui/Dashboard';
import EventEmitter from '@/libs/EventEmitter';

const MODE = {
  0: 'Default Stand',
  1: 'Balance Stand',
  2: 'Pose',
  3: 'Locomotion',
  4: 'Reserve',
  5: 'Lie Down',
  6: 'Joint Lock',
  7: 'Damping',
  8: 'Recovery Stand',
  9: 'Reserve',
  10: 'Sit',
  11: 'Front Flip',
  12: 'Front Jump',
  13: 'Front Pounce',
};

const Component = ({ robotId }) => {
  const [mode, setMode] = useState();

  useMountEffect(() => {
    const subscribeToken = EventEmitter.subscribe(`${robotId}/telemetry/sportmodestate`, (data) => {
      setMode(MODE[data.mode]);
    });

    return () => {
      EventEmitter.unsubscribe(subscribeToken);
    };
  });

  return <Mode robotId={robotId} mode={mode} />;
};

export default Component;
