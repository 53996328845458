import produce from 'immer';
import moment from 'moment';
import { handleActions } from 'redux-actions';
import { v4 as uuidv4 } from 'uuid';

const initialState = {};

export default handleActions(
  {
    'NOTIFICATION/RESET': (state, action) => initialState,
    'NOTIFICATION/NOTIFY': (state, action) =>
      produce(state, (draft) => {
        const { robotId, text } = action.payload;
        const notification = {
          id: uuidv4(),
          text: `${text} ${text}`,
          time: moment().format('HH:mm:ss'),
          isOld: false,
        };

        if (state[robotId]) {
          draft[robotId].unshift(notification);
        } else {
          draft[robotId] = [notification];
        }
      }),
    'NOTIFICATION/REMOVE': (state, action) =>
      produce(state, (draft) => {
        const { robotId } = action.payload;
        delete draft[robotId];
      }),
    'NOTIFICATION/MARK_AS_OLD': (state, action) =>
      produce(state, (draft) => {
        const { robotId, notificationId } = action.payload;
        draft[robotId].find(({ id }) => id === notificationId).isOld = true;
      }),
  },
  initialState
);
