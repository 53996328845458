import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import { fromLonLat } from 'ol/proj';
import React, { useRef } from 'react';
import { createRoot } from 'react-dom/client';
import { useSelector } from 'react-redux';

import PathDistanceLabel from '@/components/ui/map/PathDistanceLabel';
import OlMap from '@/helpers/OlMap';

const Labels = () => {
  const latestLabels = useSelector((state) => state.editor.labels);
  const addedLabels = useRef([]);
  const map = OlMap.getMap();

  useUpdateEffect(() => {
    //#region 라벨 제거
    const willBeRemoved = addedLabels.current.filter((label) => {
      const found = latestLabels.find(({ id }) => id === label.id);

      return (
        !found ||
        found.from.lat !== label.from.lat ||
        found.from.lng !== label.from.lng ||
        found.to.lat !== label.to.lat ||
        found.to.lng !== label.to.lng
      );
    });

    willBeRemoved.forEach((label) => {
      map.removeOverlay(label.overlay);

      const index = addedLabels.current.indexOf(label);
      addedLabels.current.splice(index, 1);
    });
    //#endregion

    //#region 라벨 추가
    const willBeAdded = latestLabels.filter((label) => {
      return !addedLabels.current.some(({ id }) => id === label.id);
    });

    willBeAdded.forEach((label, index) => {
      const { from, to } = label;
      const element = document.createElement('div');
      createRoot(element).render(<PathDistanceLabel points={{ from, to }} />);

      const overlay = OlMap.createOverlay({
        element,
        position: fromLonLat([(from.lng + to.lng) / 2, (from.lat + to.lat) / 2]),
        positioning: 'center-center',
        stopEvent: false,
      });

      map.addOverlay(overlay);
      addedLabels.current.splice(index, 0, { ...label, overlay });
    });
    //#endregion
  }, [latestLabels]);

  return null;
};

export default Labels;
