import classNames from 'classnames/bind';
import React from 'react';

import ClickPoint from './01_ClickPoint';
import DrawLine from './02_DrawLine';
import DrawArea from './03_DrawArea';
import DrawPolygon from './04_DrawPolygon';
import styles from './index.module.scss';

import Bar from '@/components/ui/Bar';

const cx = classNames.bind(styles);

const DrawingToolbar = () => {
  return (
    <div className={cx('container')}>
      <ClickPoint />
      <Bar height={12} />
      <DrawLine />
      <Bar height={12} />
      <DrawArea />
      <Bar height={12} />
      <DrawPolygon />
    </div>
  );
};

export default DrawingToolbar;
