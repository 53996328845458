import useMountEffect from '@restart/hooks/useMountEffect';
import React, { useState } from 'react';

import { Status } from '@/components/ui/Dashboard';
import EventEmitter from '@/libs/EventEmitter';

const Component = ({ robotId }) => {
  const [gpsRaw, setGpsRaw] = useState({ satellitesVisible: null });

  useMountEffect(() => {
    const subscribeToken = EventEmitter.subscribe(`${robotId}/telemetry/gpsRaw`, setGpsRaw);

    return () => {
      EventEmitter.unsubscribe(subscribeToken);
    };
  });

  return <Status data={{ satellites: gpsRaw.satellitesVisible }} />;
};

export default Component;
