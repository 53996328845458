import classNames from 'classnames/bind';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import styles from './LineDrawer.module.scss';

import actions from '@/actions';
import { getElevation } from '@/utils/MapUtils';

const cx = classNames.bind(styles);

const LineDrawer = ({ points, onCancelOrSubmit }) => {
  const dispatch = useDispatch();

  const cancel = () => {
    onCancelOrSubmit();
  };

  const submit = () => {
    onCancelOrSubmit();

    Promise.all(points.map(getElevation)).then((elevations) => {
      points.forEach((position, index) => {
        const waypoint = { position, elevation: elevations[index] };
        dispatch(actions.editor.appendWaypoint(waypoint));
      });
    });
  };

  const isExistPath = useMemo(() => {
    return points !== undefined;
  }, [points]);

  return (
    <div className={cx('container')}>
      {!isExistPath && <div className={cx('guide')}>Please draw the line.</div>}
      {isExistPath && <div className={cx('guide')}>Adjust the line.</div>}
      {isExistPath && (
        <div className={cx('bottom')}>
          <button type="button" className={cx('button')} onClick={cancel}>
            Cancel
          </button>
          <button type="button" className={cx(['button', 'accent'])} onClick={submit}>
            Confirm
          </button>
        </div>
      )}
    </div>
  );
};

export default LineDrawer;
