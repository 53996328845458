import { MavLinkData, MavLinkPacketField } from 'node-mavlink';

class UsvData extends MavLinkData {
  constructor() {
    super();
    this.oilcollMotorEncCnt = 0;
    this.oilcollMotorRotDir = 0;
    this.oilcollMotorRotSpd = 0;
    this.thrUtilRate = 0;
    this.heading = 0;
    this.speed = 0;
    this.latitude = 0;
    this.longitude = 0;
    this.bms1Soc = 0;
    this.bms1Vbat = 0;
    this.bms1Curr = 0;
    this.bms1TempMax = 0;
    this.bms1TempMin = 0;
    this.bms1VbatErr = 0;
    this.bms1TempErr = 0;
    this.bms1CurrErr = 0;
    this.bms1SocErr = 0;
    this.bms1DiffvErr = 0;
    this.bms1DifftErr = 0;
    this.bms1DefaultErr = 0;
    this.bms2Soc = 0;
    this.bms2Vbat = 0;
    this.bms2Curr = 0;
    this.bms2TempMax = 0;
    this.bms2TempMin = 0;
    this.bms2VbatErr = 0;
    this.bms2TempErr = 0;
    this.bms2CurrErr = 0;
    this.bms2SocErr = 0;
    this.bms2DiffvErr = 0;
    this.bms2DifftErr = 0;
    this.bms2DefaultErr = 0;
    this.powerAct = 0;
    this.posXError = 0;
    this.posYError = 0;
    this.angYawErr = 0;
  }
}

UsvData.MSG_ID = 1101;
UsvData.MSG_NAME = 'UsvData';
UsvData.PAYLOAD_LENGTH = 76;
UsvData.MAGIC_NUMBER = 229;
UsvData.FIELDS = [
  new MavLinkPacketField('oilcollMotorEncCnt', 'oilcollMotorEncCnt', 0, false, 4, 'int32_t', ''),
  new MavLinkPacketField('heading', 'heading', 4, false, 4, 'float', ''),
  new MavLinkPacketField('speed', 'speed', 8, false, 4, 'float', ''),
  new MavLinkPacketField('latitude', 'latitude', 12, false, 4, 'int32_t', ''),
  new MavLinkPacketField('longitude', 'longitude', 16, false, 4, 'int32_t', ''),
  new MavLinkPacketField('posXError', 'posXError', 20, false, 4, 'float', ''),
  new MavLinkPacketField('posYError', 'posYError', 24, false, 4, 'float', ''),
  new MavLinkPacketField('angYawErr', 'angYawErr', 28, false, 4, 'float', ''),
  new MavLinkPacketField('bms1Soc', 'bms1Soc', 32, false, 2, 'uint16_t', ''),
  new MavLinkPacketField('bms1Vbat', 'bms1Vbat', 34, false, 2, 'uint16_t', ''),
  new MavLinkPacketField('bms1Curr', 'bms1Curr', 36, false, 2, 'uint16_t', ''),
  new MavLinkPacketField('bms1TempMax', 'bms1TempMax', 38, false, 2, 'uint16_t', ''),
  new MavLinkPacketField('bms1TempMin', 'bms1TempMin', 40, false, 2, 'uint16_t', ''),
  new MavLinkPacketField('bms2Soc', 'bms2Soc', 42, false, 2, 'uint16_t', ''),
  new MavLinkPacketField('bms2Vbat', 'bms2Vbat', 44, false, 2, 'uint16_t', ''),
  new MavLinkPacketField('bms2Curr', 'bms2Curr', 46, false, 2, 'uint16_t', ''),
  new MavLinkPacketField('bms2TempMax', 'bms2TempMax', 48, false, 2, 'uint16_t', ''),
  new MavLinkPacketField('bms2TempMin', 'bms2TempMin', 50, false, 2, 'uint16_t', ''),
  new MavLinkPacketField('oilcollMotorRotDir', 'oilcollMotorRotDir', 52, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('oilcollMotorRotSpd', 'oilcollMotorRotSpd', 53, false, 1, 'uint8_t[]', '', 4),
  new MavLinkPacketField('thrUtilRate', 'thrUtilRate', 57, false, 1, 'int8_t[]', '', 4),
  new MavLinkPacketField('bms1VbatErr', 'bms1VbatErr', 61, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('bms1TempErr', 'bms1TempErr', 62, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('bms1CurrErr', 'bms1CurrErr', 63, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('bms1SocErr', 'bms1SocErr', 64, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('bms1DiffvErr', 'bms1DiffvErr', 65, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('bms1DifftErr', 'bms1DifftErr', 66, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('bms1DefaultErr', 'bms1DefaultErr', 67, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('bms2VbatErr', 'bms2VbatErr', 68, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('bms2TempErr', 'bms2TempErr', 69, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('bms2CurrErr', 'bms2CurrErr', 70, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('bms2SocErr', 'bms2SocErr', 71, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('bms2DiffvErr', 'bms2DiffvErr', 72, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('bms2DifftErr', 'bms2DifftErr', 73, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('bms2DefaultErr', 'bms2DefaultErr', 74, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('powerAct', 'powerAct', 75, false, 1, 'uint8_t', ''),
];

export default UsvData;
