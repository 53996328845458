import UsvData from './UsvData';
import UsvEmergencyGcsData from './UsvEmergencyGcsData';
import UsvOilactGcsData from './UsvOilactGcsData';

export { UsvData, UsvEmergencyGcsData, UsvOilactGcsData };

export const REGISTRY = {
  1101: UsvData,
  1102: UsvEmergencyGcsData,
  1104: UsvOilactGcsData,
};
