import API from './API';

import { isElectron } from '@/environment';

// 로그아웃
export const postLogout = () => {
  if (isElectron) {
    return new Promise((resolve) => {
      resolve({ success: true });
    });
  }

  return API.post('/auth/logout');
};

// 로봇 수정
export const patchRobot = (robotId, data) => {
  if (isElectron) {
    return new Promise((resolve) => {
      resolve({ success: true });
    });
  }

  return API.patch(`/robots/${robotId}`, data);
};

// 미션 저장
export const postMission = (name, json) => {
  if (isElectron) {
    return new Promise((resolve) => {
      window.electron.ipcRenderer.send('requestPostMission', { name, json });
      window.electron.ipcRenderer.once('responsePostMission', ({ id }) => {
        resolve({ success: true, data: { id } });
      });
    });
  }

  return API.post('/missions', { name, json });
};

// 미션 내역 조회
export const getMissions = () => {
  if (isElectron) {
    return new Promise((resolve) => {
      window.electron.ipcRenderer.send('requestGetMissions');
      window.electron.ipcRenderer.once('responseGetMissions', (data) => {
        resolve({ success: true, data });
      });
    });
  }

  return API.get('/missions');
};

// 미션 상세 조회
export const getMission = (id) => {
  if (isElectron) {
    return new Promise((resolve) => {
      window.electron.ipcRenderer.send('requestGetMission', { id });
      window.electron.ipcRenderer.once('responseGetMission', (data) => {
        resolve({ success: true, data });
      });
    });
  }

  return API.get(`/missions/${id}`);
};

// 미션 삭제
export const deleteMission = (id) => {
  if (isElectron) {
    return new Promise((resolve) => {
      window.electron.ipcRenderer.send('requestDeleteMission', { id });
      window.electron.ipcRenderer.once('responseDeleteMission', () => {
        resolve({ success: true });
      });
    });
  }

  return API.delete(`/missions/${id}`);
};

// 미션 수정
export const patchMission = (id, name, json) => {
  if (isElectron) {
    return new Promise((resolve) => {
      window.electron.ipcRenderer.send('requestPatchMission', { id, name, json });
      window.electron.ipcRenderer.once('responsePatchMission', () => {
        resolve({ success: true });
      });
    });
  }

  return API.patch(`/missions/${id}`, { name, json });
};
