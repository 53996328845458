import useMountEffect from '@restart/hooks/useMountEffect';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import classNames from 'classnames/bind';
import moment from 'moment';
import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import CookiePolicy from './CookiePolicy';
import styles from './index.module.scss';

import Checkbox from '@/components/ui/Checkbox';
import API, { setAuthToken } from '@/helpers/API';
import { ToastService as toast } from '@/libs/Toast';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({
    loginId: '',
    password: '',
  });
  const [isRememberId, setRememberId] = useState(false);
  const [existSession, setExistSession] = useState();
  const loginIdRef = useRef();
  const passwordRef = useRef();

  useMountEffect(() => {
    const loginId = localStorage.getItem('@loginId');
    // 아이디 저장된 경우
    if (loginId) {
      setUserInfo({ ...userInfo, loginId });
      setRememberId(true);
    }

    // 아이디 입력 필드 포커스
    loginIdRef.current.focus();
  });

  useUpdateEffect(() => {
    if (!existSession) return;

    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [existSession]);

  const onKeyDown = (e) => {
    if (e.code === 'Enter') {
      doLoginWithTicket();
    }
  };

  const handleChange = (e) => {
    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value,
    });
  };

  const pressEnter = (e) => {
    if (e.code === 'Enter') {
      switch (e.target.name) {
        case 'loginId':
          passwordRef.current.focus();
          break;
        case 'password':
          doLogin();
          break;
        default:
          break;
      }
    }
  };

  const toggle = () => {
    setRememberId(!isRememberId);
  };

  // 유효성 검사
  const validAll = () => {
    if (userInfo.loginId.trim() === '') {
      toast.error('Please enter your email.');
      loginIdRef.current.focus();
      return false;
    }
    if (userInfo.password.trim() === '') {
      toast.error('Please enter your password.');
      passwordRef.current.focus();
      return false;
    }
    return true;
  };

  // 로그인
  const doLogin = () => {
    if (!validAll()) return;

    API.post('/auth/login', userInfo).then(({ success, data }) => {
      if (success) {
        // 중복 로그인 아닐 시
        if (data.accessToken) {
          successLogin(data.accessToken);
        }
        // 중복 로그인 시
        else if (data.ticket) {
          setExistSession(data);
        }
      } else {
        toast.error('Please check your email or password.');
      }
    });
  };

  const reset = () => {
    setExistSession();
  };

  // 티켓 로그인
  const doLoginWithTicket = () => {
    // API: 로그인
    API.post('/auth/login/ticket', { ticket: existSession.ticket }).then(({ success, data }) => {
      if (success) {
        successLogin(data.accessToken);
      }
    });
  };

  // 로그인 성공 시 처리
  const successLogin = (accessToken) => {
    // 아이디 저장
    if (isRememberId) {
      localStorage.setItem('@loginId', userInfo.loginId);
    }

    setAuthToken(accessToken);
    navigate('/control-center', { replace: true });
  };

  return (
    <div className={cx('container')}>
      <form className={cx('form')}>
        <div className={cx(['formInner', { hidden: existSession }])}>
          <input
            ref={loginIdRef}
            name="loginId"
            type="text"
            value={userInfo.loginId}
            placeholder="User ID (Email)"
            onChange={handleChange}
            onKeyDown={pressEnter}
            className={cx('field')}
            autoComplete="username"
          />
          <input
            ref={passwordRef}
            name="password"
            type="password"
            value={userInfo.password}
            placeholder="Password"
            onChange={handleChange}
            onKeyDown={pressEnter}
            className={cx('field')}
            autoComplete="current-password"
          />
          <div className={cx('option')} onClick={toggle}>
            <Checkbox checked={isRememberId} size={24} />
            <div className={cx('remember')}>Remember ID</div>
          </div>
          <button type="button" className={cx(['button', 'accent'])} onClick={doLogin}>
            Login
          </button>
        </div>
        {existSession && (
          <div className={cx('formInner')}>
            <div className={cx('timestamp')}>
              <small>Last logged in {moment(existSession.lastLoggedAt).format('YYYY-MM-DD HH:mm:ss')}</small>
              <br />
              This account is currently online.
              <br />
              <span className={cx('accent')}>Your previous connection will be terminated.</span>
            </div>
            <button type="button" className={cx(['button', 'accent'])} onClick={doLoginWithTicket}>
              Login
            </button>
            <button type="button" className={cx(['button', 'cancel'])} onClick={reset}>
              Cancel
            </button>
          </div>
        )}
      </form>
      <CookiePolicy />
    </div>
  );
};

export default Page;
