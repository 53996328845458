let handler;

const setHandler = (_handler) => {
  handler = _handler;
};

const success = (content) => {
  handler('success', content);
};

const error = (content) => {
  handler('error', content);
};

export { setHandler };

// eslint-disable-next-line import/no-anonymous-default-export
export default { success, error };
