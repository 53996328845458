import classNames from 'classnames/bind';
import React from 'react';

import styles from './Row.module.scss';

const cx = classNames.bind(styles);

const Row = ({ icon: Icon, label, value, unit, accent = true, valueStyle = {} }) => {
  return (
    <div className={cx(['container', { accent }])}>
      <div className={cx('left')}>
        {Icon && <Icon size={18} />}
        <div className={cx('label')}>{label}</div>
      </div>
      <div className={cx('value')} style={valueStyle ? valueStyle : {}}>
        {value}
        <span className={cx('unit')}>{unit}</span>
      </div>
    </div>
  );
};

export default Row;
