import { RiToolsLine } from 'react-icons/ri';

// Inspection
import Diagnostics from './01_Inspection/01_Diagnostics';

const commands = [
  {
    name: 'Inspection',
    subcommands: [{ name: 'Diagnostics', icon: RiToolsLine, component: Diagnostics }],
  },
];

export default commands;
