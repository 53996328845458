import classNames from 'classnames/bind';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './WrongMission.module.scss';

import ModalWrapper from '@/components/ui/Modal';
import { ModalService as modal } from '@/libs/Modal';

const cx = classNames.bind(styles);

const Modal = () => {
  const navigate = useNavigate();

  const doConfirm = () => {
    navigate('/mission-hub/new', { replace: true });
    modal.hide();
  };

  return (
    <ModalWrapper disableEscape>
      <div className={cx('wrapper')}>
        <div className={cx('message')}>The mission does not exist. Work on a new mission.</div>
        <div className={cx('bottom')}>
          <button type="button" className={cx(['button', 'accent'])} onClick={doConfirm}>
            New
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default Modal;
