import * as turf from '@turf/turf';
import axios from 'axios';
import * as mgrs from 'mgrs';

import CoordUtils from './CoordUtils';

import { BING_API_KEY, VIRTUALEARTH_BASE_URL } from '@/config';

// 거리(m) 획득
export const getDistance = (from, to) => {
  from = CoordUtils.arrayFromObject(from);
  to = CoordUtils.arrayFromObject(to);

  return turf.distance(from, to) * 1000;
};

// 경로 총 거리(m) 획득
export const getDistanceAlongPath = (path) => {
  if (path.length < 2) return 0;

  const line = turf.lineString(path.map(({ lat, lng }) => [lng, lat]));
  return turf.length(line) * 1000;
};

// 위치 고도 획득
export const getElevation = async (position) => {
  const { lat, lng } = getValidCoords(position);

  const result = await axios.get(`${VIRTUALEARTH_BASE_URL}/Elevation/List`, {
    params: {
      key: BING_API_KEY,
      points: `${lat},${lng}`,
    },
  });
  // Elevation is always an integer
  return result.data.resourceSets[0].resources[0].elevations[0];
};

// 경로 고도 획득
export const getElevationAlongPath = async (path) => {
  const subpaths = [];
  for (let index = 0; index < path.length; index += 1024) {
    subpaths.push(path.slice(index, index + 1024));
  }

  const promises = subpaths.map((subpath) => {
    const points = getEncodedPoints(subpath);
    const distance = getDistanceAlongPath(subpath);
    const samples = Math.max(2, Math.min(Math.ceil(distance / 2), 1024));

    return axios.get(`${VIRTUALEARTH_BASE_URL}/Elevation/Polyline`, {
      params: {
        key: BING_API_KEY,
        points,
        samples,
      },
    });
  });

  const results = await Promise.all(promises);
  return results.flatMap((result) => result.data.resourceSets[0].resources[0].elevations);
};

// 좌표 → MGRS
export const getMGRS = ({ lat, lng }) => {
  try {
    return mgrs.forward([lng, lat]);
  } catch (error) {
    console.error(error.message);
    return 'OUT OF BOUNDS';
  }
};

// MGRS → 좌표
export const getFromMGRS = (geocode) => {
  try {
    return CoordUtils.objectFromArray(mgrs.toPoint(geocode));
  } catch (error) {
    console.error(error.message);
    return null;
  }
};

// 지오코딩 (주소 → 좌표)
export const geocoding = async ({ address }) => {
  try {
    const result = await axios.get(`${VIRTUALEARTH_BASE_URL}/Locations`, {
      params: {
        key: BING_API_KEY,
        query: encodeURIComponent(address),
      },
    });
    const [lat, lng] = result.data.resourceSets[0].resources[0].point.coordinates;

    return { lat, lng };
  } catch {
    throw new Error('There are no matching addresses.');
  }
};

// GPS 유효 데이터 수신 전 위치 정보
export const isColdStartLocation = (lat, lng) => {
  return Math.abs(lat) < 1 || Math.abs(lng) < 1;
};

const getValidCoords = ({ lat, lng }) => {
  lat = Math.max(-85, Math.min(lat, 85));
  lng = Math.max(-180, Math.min(lng, 180));

  return { lat, lng };
};

// Point Compression
// https://learn.microsoft.com/en-us/bingmaps/rest-services/elevations/point-compression-algorithm#algorithm-description
const getEncodedPoints = (points) => {
  const results = [];
  const encodeChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_-';

  let latitude = 0;
  let longitude = 0;

  points.forEach(({ lat, lng }) => {
    const nextLatitude = Math.round(lat * 100000);
    const nextLongitude = Math.round(lng * 100000);

    let dy = nextLatitude - latitude;
    let dx = nextLongitude - longitude;
    latitude = nextLatitude;
    longitude = nextLongitude;

    dy = (dy << 1) ^ (dy >> 31);
    dx = (dx << 1) ^ (dx >> 31);

    let index = ((dy + dx) * (dy + dx + 1)) / 2 + dy;

    while (index > 0) {
      let rem = index & 31;
      index = (index - rem) / 32;

      if (index > 0) rem += 32;

      results.push(encodeChars[rem]);
    }
  });

  return results.join('');
};
