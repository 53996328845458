import produce from 'immer';
import { handleActions } from 'redux-actions';

const initialState = {};

export default handleActions(
  {
    'EVENT/RESET': (state, action) => initialState,
    'EVENT/LOAD': (state, action) =>
      produce(state, (draft) => {
        const { data } = action.payload;

        draft[data.robotId] = data.payload;
      }),
    'EVENT/REMOVE': (state, action) =>
      produce(state, (draft) => {
        const { robotId } = action.payload;
        delete draft[robotId];
      }),
  },
  initialState
);
