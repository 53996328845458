import useMountEffect from '@restart/hooks/useMountEffect';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';

import actions from '@/actions';
import { MessageContext } from '@/helpers/MessageProvider';
import EventEmitter from '@/libs/EventEmitter';

const Action = ({ data: robots }) => {
  const dispatch = useDispatch();
  const { publishCommand } = useContext(MessageContext);

  useMountEffect(() => {
    robots.forEach((robot) => {
      publishCommand(robot, 'action/brake/arducopter', [[]]);
      EventEmitter.publish(`${robot.id}/event/removeGoto`);
    });

    dispatch(actions.command.toggle());
  });

  return null;
};

export default Action;
