// prettier-ignore
const GEO_ZONES = {
  UAC:  { color: '#D84B20', title: '초경량비행장치비행공역' },
  CTRC: { color: '#424632', title: '관제권' },
  PRHC: { color: '#79553D', title: '비행금지구역' },
  RESC: { color: '#332F2C', title: '비행제한구역' },
  ATZC: { color: '#924E7D', title: '비행장교통구역' },
  DNGC: { color: '#79553D', title: '위험구역' },
  MOAC: { color: '#CB3234', title: '군작전구역' },
  CATC: { color: '#6C4675', title: '훈련구역' },
  FLDC: { color: '#063971', title: '경량항공기이착륙장' },
};

export default GEO_ZONES;
