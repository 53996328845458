let handler;

const setHandler = (_handler) => {
  handler = _handler;
};

const show = (component, props) => {
  handler(component, props);
};

const hide = () => {
  handler();
};

export { setHandler };

// eslint-disable-next-line import/no-anonymous-default-export
export default { show, hide };
