import useMountEffect from '@restart/hooks/useMountEffect';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import classNames from 'classnames/bind';
import commaNumber from 'comma-number';
import moment from 'moment';
import { defaults } from 'ol/control/defaults';
import { boundingExtent } from 'ol/extent';
import Feature from 'ol/Feature';
import { LineString, Point } from 'ol/geom';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import Map from 'ol/Map';
import { fromLonLat } from 'ol/proj';
import { Vector as VectorSource, BingMaps } from 'ol/source';
import { Style, Stroke, Icon } from 'ol/style';
import View from 'ol/View';
import React, { useState, useRef, useMemo } from 'react';
import { useStore } from 'react-redux';

import styles from './OperationHistory.module.scss';

import arrowIcon from '@/assets/vectors/arrow.svg';
import EmptyList from '@/components/ui/EmptyList';
import ModalWrapper from '@/components/ui/Modal';
import ModalSpinner from '@/components/ui/ModalSpinner';
import { BING_API_KEY, OL_MAX_ZOOM } from '@/config';
import API from '@/helpers/API';
import { ModalService as modal } from '@/libs/Modal';
import { getDuration } from '@/utils/Formatter';
import { getDistanceAlongPath } from '@/utils/MapUtils';

const cx = classNames.bind(styles);

const Modal = () => {
  const store = useStore();
  const [operations, setOperations] = useState();
  const [selectedRobotId, setSelectedRobotId] = useState();
  const [selectedOperationId, setSelectedOperationId] = useState();
  const [showingOperation, setShowingOperation] = useState();
  const [isLoading, setLoading] = useState(false);

  const map = useRef();
  const mapRef = useRef();
  const layer = useRef();
  const savedOperations = useRef({});

  const filteredOperations = useMemo(() => {
    if (selectedRobotId) {
      return operations.filter(({ robot }) => robot.id === selectedRobotId);
    }
    return operations;
  }, [operations, selectedRobotId]);

  const existPath = useMemo(() => {
    return showingOperation?.path.length > 0 ?? false;
  }, [showingOperation]);

  useMountEffect(() => {
    setLoading(true);

    // API: 운행이력 조회
    API.get('/robots/operations')
      .then(({ success, data }) => {
        if (success) {
          const nextHistory = data.map((item) => {
            const robot = store.getState().robot.robots.find(({ id }) => id === item.robotId);
            return {
              id: item.id,
              robot,
              startedAt: item.startedAt,
              endedAt: item.endedAt,
            };
          });
          nextHistory.sort((a, b) => (moment(a.startedAt).isAfter(moment(b.startedAt)) ? -1 : 1));
          setOperations(nextHistory);
        } else {
          setOperations([]);
        }
      })
      .finally(() => setLoading(false));

    // Map 초기화
    map.current = new Map({
      target: mapRef.current,
      layers: [
        new TileLayer({
          source: new BingMaps({ key: BING_API_KEY, imagerySet: 'Aerial' }),
        }),
      ],
      view: new View({ maxZoom: OL_MAX_ZOOM }),
      controls: defaults({ attribution: false, rotate: false, zoom: false }),
    });
  });

  useUpdateEffect(() => {
    if (!selectedOperationId) return;

    // 이미 조회된 운행이력의 운행
    const found = Object.entries(savedOperations.current).find(([id]) => id === selectedOperationId);
    if (found) {
      setShowingOperation(found[1]);
      return;
    }

    setLoading(true);

    // API: 운행이력 조회
    API.get(`/robots/operations/${selectedOperationId}`)
      .then(({ success, data }) => {
        if (success) {
          // 초당 1개 이력 구성
          const filtered = [data[0]];
          for (const item of data) {
            // 직전 시각
            const prev = new Date(filtered.slice(-1)[0].time);
            const prevSec = Math.floor(prev.getTime() / 1000);
            // 현재 시각
            const curr = new Date(item.time);
            const currSec = Math.floor(curr.getTime() / 1000);

            if (prevSec !== currSec) {
              filtered.push(item);
            }
          }

          // 경로정보 구성
          const path = filtered.map(({ lat, lon }) => ({
            lat: lat / 10000000,
            lng: lon / 10000000,
          }));

          // 이동거리
          const distance = getDistanceAlongPath(path);
          // 운행 시작 및 종료시각
          const { startedAt, endedAt } = operations.find(({ id }) => id === selectedOperationId);
          // 운행시간
          const duration = getDuration(startedAt, endedAt);
          // 평균속력
          const averageSpeed = distance / moment(endedAt).diff(moment(startedAt));

          const nextOperation = {
            path,
            stats: {
              distance,
              duration,
              averageSpeed,
            },
          };
          savedOperations.current[selectedOperationId] = nextOperation;
          setShowingOperation(nextOperation);
        }
      })
      .finally(() => setLoading(false));
  }, [selectedOperationId]);

  useUpdateEffect(() => {
    if (!showingOperation) return;

    // 경로 정의
    const path = showingOperation.path.map(({ lat, lng }) => fromLonLat([lng, lat]));

    // 끝점 화살표 방향 정의
    const [x1, y1] = path.at(-2);
    const [x2, y2] = path.at(-1);
    const heading = Math.atan2(y2 - y1, x2 - x1) - Math.PI / 4;

    // 기존 경로
    if (layer.current) {
      const features = layer.current.getSource().getFeatures();

      features.forEach((feature) => {
        // 경로인 경우
        if (feature.getGeometry().getType() === 'LineString') {
          feature.getGeometry().setCoordinates(path);
        }
        // 화살표인 경우
        else {
          feature.getGeometry().setCoordinates(path.at(-1));
          feature.getStyle().getImage().setRotation(-heading);
        }
      });
    }
    // 신규 경로
    else {
      const line = new Feature(new LineString(path));
      const arrow = new Feature(new Point(path.at(-1)));

      // 경로 Style 정의
      line.setStyle(
        new Style({
          stroke: new Stroke({
            color: '#41a3ff',
            width: 4,
          }),
        })
      );
      // 화살표 Style 정의
      arrow.setStyle(
        new Style({
          image: new Icon({
            src: arrowIcon,
            rotation: -heading,
          }),
        })
      );

      const source = new VectorSource({ features: [line, arrow] });
      layer.current = new VectorLayer({ source });
      map.current.addLayer(layer.current);
    }

    // 맵 중심 조정
    const extent = boundingExtent(path);
    map.current.getView().fit(extent, { padding: [40, 40, 40, 40] });
  }, [showingOperation]);

  const selectRobot = (e) => {
    setShowingOperation();
    setSelectedOperationId();
    setSelectedRobotId(e.target.value);
  };

  const selectHistory = (e) => {
    setShowingOperation();

    if (selectedOperationId === e.currentTarget.dataset.id) {
      setSelectedOperationId();
    } else {
      setSelectedOperationId(e.currentTarget.dataset.id);
    }
  };

  const close = () => {
    modal.hide();
  };

  return (
    <ModalWrapper>
      <div className={cx('wrapper')}>
        <div>
          <select onChange={selectRobot}>
            <option value="">All</option>
            {store.getState().robot.robots.map((robot, index) => (
              <option key={index} value={robot.id}>
                {robot.name}
              </option>
            ))}
          </select>
          <div className={cx('table')}>
            <div className={cx('header')}>
              <div className={cx(['column', 'no'])}>No</div>
              <div className={cx(['column', 'name'])}>Robot</div>
              <div className={cx(['column', 'time'])}>Start</div>
              <div className={cx(['column', 'time'])}>End</div>
              <div className={cx(['column', 'duration'])}>Duration</div>
            </div>
            <div className={cx('body')}>
              {isLoading && <ModalSpinner />}
              {filteredOperations?.length === 0 && (
                <div className={cx('empty')}>
                  <EmptyList />
                </div>
              )}
              {filteredOperations?.map((operation, index) => {
                const isTarget = operation.id === selectedOperationId;
                const startedAt = moment(operation.startedAt).format('YYYY-MM-DD HH:mm:ss');
                const endedAt = moment(operation.endedAt).format('YYYY-MM-DD HH:mm:ss');
                const duration = getDuration(operation.startedAt, operation.endedAt);

                return (
                  <div
                    key={index}
                    data-id={operation.id}
                    onClick={selectHistory}
                    className={cx(['row', { target: isTarget }])}>
                    <div className={cx(['column', 'no'])}>{filteredOperations.length - index}</div>
                    <div className={cx(['column', 'name'])}>{operation.robot.name}</div>
                    <div className={cx(['column', 'time'])}>{startedAt}</div>
                    <div className={cx(['column', 'time'])}>{endedAt}</div>
                    <div className={cx(['column', 'duration'])}>{duration}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className={cx('mapWrapper')}>
          <div ref={mapRef} className={cx(['map', { show: existPath }])} />
          {!selectedOperationId && (
            <div className={cx('empty')}>
              <EmptyList message="No item selected" />
            </div>
          )}
          {selectedOperationId && !existPath && (
            <div className={cx('empty')}>
              <EmptyList message="No route collected" />
            </div>
          )}
          {selectedOperationId && existPath && (
            <div className={cx('info')}>
              <div className={cx('item')}>
                <div className={cx('label')}>Distance</div>
                <div className={cx('value')}>
                  {commaNumber((showingOperation.stats.distance / 1000).toFixed(1))}
                  <span>km</span>
                </div>
              </div>
              <div className={cx('item')}>
                <div className={cx('label')}>Duration</div>
                <div className={cx('value')}>{showingOperation.stats.duration}</div>
              </div>
              <div className={cx('item')}>
                <div className={cx('label')}>Avg. Speed</div>
                <div className={cx('value')}>
                  {commaNumber((showingOperation.stats.averageSpeed * 1000).toFixed(1))}
                  <span>m/s</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={cx('bottom')}>
        <button type="button" className={cx('button')} onClick={close}>
          Close
        </button>
      </div>
    </ModalWrapper>
  );
};

export default Modal;
