import classNames from 'classnames/bind';
import React, { useState, useMemo } from 'react';
import { RiCheckboxBlankCircleLine, RiCheckboxCircleLine, RiMap2Line } from 'react-icons/ri';

import styles from './MapSelector.module.scss';
import AccordionCaret from '../../AccordionCaret';

import { OL_DEFAULT_MAP_ID } from '@/config';
import MAPS from '@/define/olMaps';
import OlMap from '@/helpers/OlMap';
import { ToastService as toast } from '@/libs/Toast';

const cx = classNames.bind(styles);

const MapSelector = () => {
  const [showPanel, setShowPanel] = useState(false);
  const [currentItem, setCurrentItem] = useState({
    mapId: OL_DEFAULT_MAP_ID,
    typeIndex: 0,
  });

  const title = useMemo(() => {
    const { name, types } = MAPS[currentItem.mapId];
    return `${name} - ${types[currentItem.typeIndex].name}`;
  }, [currentItem]);

  const togglePanel = () => {
    setShowPanel(!showPanel);
  };

  const changeMap = (mapId, typeIndex) => {
    const { lat, lng } = OlMap.getCenter();
    if (mapId === 'VWORLD' && !OlMap.checkInsideOfVWorld([lng, lat])) {
      toast.error('This location is not supported by VWorld.');
      return;
    }

    setCurrentItem({ mapId, typeIndex });
    OlMap.changeMap(mapId, typeIndex);
  };

  return (
    <div className={cx('container')} onClick={togglePanel}>
      <div className={cx('left')}>
        <RiMap2Line size={16} />
        <div className={cx('title')}>{title}</div>
      </div>
      <AccordionCaret up={showPanel} size={32} />
      <div className={cx(['panel', { show: showPanel }])}>
        {Object.entries(MAPS).map(([mapId, map]) => (
          <div key={mapId} className={cx('group')}>
            <div className={cx('header')}>
              <div className={cx('name')}>{map.name}</div>
              {map.label && <div className={cx('label')}>{map.label}</div>}
            </div>
            <div className={cx('items')}>
              {map.types.map((type, index) => {
                const isActive = mapId === currentItem.mapId && index === currentItem.typeIndex;

                return (
                  <div key={index} onClick={() => changeMap(mapId, index)} className={cx('item')}>
                    {isActive ? (
                      <RiCheckboxCircleLine size={14} color="#41a3ff" />
                    ) : (
                      <RiCheckboxBlankCircleLine size={14} color="white" />
                    )}
                    <div className={cx(['menuText', { active: isActive }])}>{type.name}</div>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MapSelector;
