import classNames from 'classnames/bind';
import commaNumber from 'comma-number';
import React from 'react';

import styles from './PathDistanceLabel.module.scss';

import { getDistance } from '@/utils/MapUtils';

const cx = classNames.bind(styles);

const PathDistanceLabel = ({ points: { from, to } }) => {
  const distance = getDistance(from, to);
  const adjacent = Math.abs(to.lat - from.lat); // 밑변
  const opposite = Math.abs(to.lng - from.lng); // 높이

  let degree = (Math.atan2(adjacent, opposite) * 180) / Math.PI;
  degree += 5; // 미세 보정
  if (
    (from.lat < to.lat && from.lng < to.lng) || // 우상향
    (from.lat > to.lat && from.lng > to.lng) // 좌하향
  ) {
    degree *= -1;
  }

  return (
    <div className={cx('container')} style={{ transform: `rotate(${degree}deg)` }}>
      {commaNumber(distance.toFixed(1))}m
    </div>
  );
};

export default PathDistanceLabel;
